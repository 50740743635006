import React, { useEffect, useState, useRef } from 'react';
import CustomPolygon from '../components/polygon';
import { MapContainer, TileLayer, FeatureGroup } from 'react-leaflet';
import { json, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Typography,
  Grid,
  Container,
  Stack,
} from '@mui/material';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Brotacion from '../img/Brotacion.png';
import RecesoInvernal from '../img/RecesoInvernal.png';
import Brote20cm from '../img/Brote20cm.png';
import Brote60cm from '../img/Brote60.png';
import Cuaje from '../img/Cuaje.png';
import Envero from '../img/Envero.png';
import Fruto5mm from '../img/Fruto5mm.png';
import Cosecha from '../img/Cosecha.png';
import PlenaFloracion from '../img/PlenaFloracion.png';
import InicioFloracion from '../img/InicioFloracion.png';
// import Etapas from '../img/Etapas.png';
import Etapas from '../img/Etapas1.png';
import * as XLSX from 'xlsx';
import dotenv from 'dotenv';
import { Calendar } from 'src/components/calendar/calendar';
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { SettingsRemoteRounded } from '@mui/icons-material';
import { set } from 'lodash';


dotenv.config();
const url = process.env.REACT_APP_BASE_URL;

const monthDosisN = {
  "Octubre": 0.25,
  "Noviembre": 0.15,
  "Diciembre": 0.2,
  "Enero": 0,
  "Febrero": 0.40,
  "Marzo": 0.40,
}
const monthDosisP = {
  "Octubre": 0.20,
  "Noviembre": 0.30,
  "Diciembre": 0.30,
  "Enero": 0.20,
  "Febrero": 0,
  "Marzo": 0,
}
const monthDosisK = {
  "Octubre": 0,
  "Noviembre": 0.2,
  "Diciembre": 0.3,
  "Enero": 0.5,
  "Febrero": 0,
  "Marzo": 0,
}
const monthDosisCa = {
  "Octubre": 0.3,
  "Noviembre": 0.4,
  "Diciembre": 0.3,
  "Enero": 0,
  "Febrero": 0,
  "Marzo": 0,
}
const monthDosisMg = {
  "Octubre": 0,
  "Noviembre": 0.3,
  "Diciembre": 0.3,
  "Enero": 0.4,
  "Febrero": 0,
  "Marzo": 0,
}

const cuartelesMap = [
  {
    "id": 1,
    "nombre": "Cuartel 25",
    "superficie": 10.37,
    "zona": 1,
    "coordenadas": [
      [-33.663721, -70.552011],
      [-33.668636, -70.551375],
      [-33.668485, -70.549437],
      [-33.663318, -70.550133],


    ],
  },
  {
    "id": 2,
    "nombre": "Cuartel 26",
    "superficie": 5.93,
    "zona": 2,
    "coordenadas": [
      [-33.663261, -70.549980], // Punto 1 arriba izq
      [-33.662924, -70.547925], // Punto 2 arriva der
      [-33.668474, -70.547186],
      [-33.668527, -70.549221],
    ],
  },

]

const sectoresMap = [
  {
    "id": 1,
    "superficie": 5.36,
    "nombre": "Sector 8",
    "cuartel": 1,
    "coordenadas": [
      [-33.672122, -70.546279],
      [-33.672198, -70.544776],
      [-33.674445, -70.544807],
      [-33.674370, -70.546400]
    ],
  },
  {
    "id": 2,
    "nombre": "Sector 9",
    "superficie": 5.01,
    "cuartel": 1,
    "coordenadas": [
      [-33.672248, -70.543153],
      [-33.672198, -70.544776],
      [-33.674445, -70.544807],
      [-33.674509, -70.543335]
    ],
  },
  {
    "id": 3,
    "nombre": "Sector 8",
    "superficie": 3.12,
    "cuartel": 2,
    "coordenadas": [
      [-33.674471, -70.546354],
      [-33.675986, -70.546461],
      [-33.676137, -70.543502],
      [-33.674673, -70.543320]
    ],
  },
  {
    "id": 4,
    "nombre": "Sector 10",
    "cuartel": 2,
    "superficie": 2.80987,
    "coordenadas": [
      [-33.675986, -70.546461],
      [-33.676137, -70.543502],
      [-33.677893, -70.543608],
      [-33.677842, -70.546506]
    ],
  },

]
function getStage(step) {
  let Stage = {
    Nombre: '',
    Imagen: '',
  };

  switch (step) {
    case 1:
      Stage.Nombre = 'Receso Invernal';
      Stage.Imagen = RecesoInvernal;
      return Stage;
    case 2:
      Stage.Nombre = 'Brotación';
      Stage.Imagen = Brotacion;
      return Stage;
    case 3:
      Stage.Nombre = 'Brote 20 cm';
      Stage.Imagen = Brote20cm;
      return Stage;
    case 4:
      Stage.Nombre = 'Brote 60 cm';
      Stage.Imagen = Brote60cm;
      return Stage;
    case 5:
      Stage.Nombre = 'Inicio Floración';
      Stage.Imagen = InicioFloracion;
      return Stage;
    case 6:
      Stage.Nombre = 'Plena Floración';
      Stage.Imagen = PlenaFloracion;
      return Stage;
    case 7:
      Stage.Nombre = 'Cuaje';
      Stage.Imagen = Cuaje;
      return Stage;
    case 8:
      Stage.Nombre = 'Fruto 5mm';
      Stage.Imagen = Fruto5mm;
      return Stage
    case 9:
      Stage.Nombre = 'Envero';
      Stage.Imagen = Envero;
      return Stage;

    case 10:
      Stage.Nombre = 'Cosecha';
      Stage.Imagen = Cosecha;
      return Stage;

  }
}




const Map = ({ listaUnidades, center, onSelectedUnidad }) => {

  const mapRef = useRef(null);
  // TODO: mostrar en el mapa cual unidad está seleccionada
  const [getSelectedPolygons, setSelectedPolygons] = useState([]);
  const [getOverMousePolygon, setOverMousePolygon] = useState({});

  // const latitude = -33.666118;
  // const longitude = -70.549456;

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.flyTo([center.latitude, center.longitude], 14);
    }
  }, [center])

  const handlerPolygonHover = (unidad) => {
    setOverMousePolygon(unidad);
  }

  const handlerPolygonMouseOut = (unidad) => {
    setOverMousePolygon({});
  }


  return (
    <MapContainer
      center={[center.latitude, center.longitude]}
      zoom={13}
      style={{ width: "100%", height: "400px", borderRadius: "6px" }}
      ref={mapRef}
    >
      <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
        attribution="MIIDO"

      />
      {listaUnidades.map((unidad) => (
        <FeatureGroup key={unidad.id} >
          <CustomPolygon
            index={unidad.id}
            unidad={unidad}
            onClick={() => {
              onSelectedUnidad(unidad);
            }}
            setMouseOver={handlerPolygonHover}
            setMouseOut={handlerPolygonMouseOut}
            selected={getSelectedPolygons.includes(unidad.id)}
          />
        </FeatureGroup>
      ))}


    </MapContainer>
  )
}


class ScheduleProducts {
  constructor() {
    this.products = [];
  }

  addProduct(month, product) {
    // validate if product is already in the list
    const productIndex = this.products.findIndex((scheduledProduct) => scheduledProduct.month === month && scheduledProduct.product.id === product.id);
    if (productIndex !== -1) {
      this.products[productIndex].month = month;
      return [...this.products];
    }

    this.products.push({
      month,
      fulfilled: 0,
      product
    });

    return [...this.products]
  }

  findProduct(month, productId) {

    if (!month || !productId) return;
    const scheduledProduct = this.products.find(
      scheduledProduct => scheduledProduct.month === month && scheduledProduct.product.id === productId
    )

    if (!scheduledProduct) return;

    return { ...scheduledProduct.product }
  }

  removeProduct(month, productId) {
    const productIndex = this.products.findIndex((scheduledProduct) => scheduledProduct.month === month && scheduledProduct.product.id === productId);
    if (productIndex === -1) return;

    this.products.splice(productIndex, 1);
    return [...this.products];
  }

  getCSVData() {
    const csvData = this.products.map((scheduledProduct) => ({
      month: scheduledProduct.month,
      ...scheduledProduct.product,
    }));

    return csvData;
  }
  modifyProduct(month, productId, fulfilled) {
    const productIndex = this.products.findIndex((scheduledProduct) => scheduledProduct.month === month && scheduledProduct.product.id === productId);
    if (productIndex === -1) return;

    this.products[productIndex].fulfilled = fulfilled;
    return [...this.products];
  }
}


const schedule = new ScheduleProducts();

export default function AplicationPage() {
  const [selectedUnits, setSelectedUnits] = useState(cuartelesMap);
  // TODO: remove default cuartel
  const [currentUnit, setCurrentUnit] = useState({
    "id": 2,
    "nombre": "Cuartel 26",
    "superficie": 5.93,
    "zona": 2,
    "coordenadas": [
      [
        -33.663261,
        -70.54998
      ],
      [
        -33.662924,
        -70.547925
      ],
      [
        -33.668474,
        -70.547186
      ],
      [
        -33.668527,
        -70.549221
      ]
    ]
  });
  const [center, setCenter] = useState({ latitude: -33.666118, longitude: -70.549456 });
  const [step, setStep] = useState(1);
  const [orderProducts, setOrderProducts] = useState({});
  const [scheduledProducts, setScheduledProducts] = useState([...schedule.products]);
  const chartRef = useRef(null);
  const [filledN, setFilledN] = useState(0);
  const [filledP, setFilledP] = useState(0);
  const [filledK, setFilledK] = useState(0);
  const [filledCa, setFilledCa] = useState(0);
  const [filledMg, setFilledMg] = useState(0);
  const [appliedN, setAppliedN] = useState(0);
  const [appliedP, setAppliedP] = useState(0);
  const [appliedK, setAppliedK] = useState(0);
  const [appliedCa, setAppliedCa] = useState(0);
  const [appliedMg, setAppliedMg] = useState(0);
  const [buyedN, setBuyedN] = useState(0);
  const [buyedP, setBuyedP] = useState(0);
  const [buyedK, setBuyedK] = useState(0);
  const [buyedCa, setBuyedCa] = useState(0);
  const [buyedMg, setBuyedMg] = useState(0);
  const [seasonValues, setSeasonValues] = useState({});

  //CHART
  useEffect(() => {
    Chart.register(ChartDataLabels);

    const necesidadData = Object.values(seasonValues);

    const chart = new Chart(chartRef.current, {
      type: "bar",
      data: {
        labels: ["N", "P", "K", "Ca", "Mg"],
        datasets: [
          {
            label: 'Unidades por superficie',
            data: necesidadData,
            borderColor: '#d9d9d9',
            barThickness: 35,
            borderRadius: 25,
          },
          {
            label: 'Unidades compradas',
            data: [buyedN, buyedP, buyedK, buyedCa, buyedMg],
            borderColor: '#87bdb9',
            backgroundColor: '#87bdb9',
            barThickness: 35,
            borderRadius: 25,
            datalabels: {
              color: 'white', // Cambia este valor al color que desees
            },
          },
          {
            label: 'Unidades asignadas',
            data: [appliedN, appliedP, appliedK, appliedCa, appliedMg],
            borderColor: '#C2DB64',
            backgroundColor: '#C2DB64',
            barThickness: 35,
            borderRadius: 25,
            datalabels: {
              color: 'white', // Cambia este valor al color que desees
            },
          }
        ]
      },
      options: {
        plugins: {
          datalabels: {

            font: {
              size: 13,
              weight: 'bold',
            }
          }
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
            reverse: true,
            grid: { display: false }
          },
          x: {
            grid: { display: false },
          }
        },
        responsive: true,
      },
    });
    return () => {
      chart.destroy();
    };
  }, [seasonValues, filledN, filledCa, filledK, filledMg, filledP, appliedN, appliedCa, appliedK, appliedMg, appliedP, buyedN, buyedCa, buyedK, buyedMg, buyedP]);

  const navigate = useNavigate();
  const months = ["Octubre", "Noviembre", "Diciembre", "Enero", "Febrero", "Marzo"];

  const handleSelectedUnidad = (unidad) => {
    setCurrentUnit(unidad);
  };

  const loadCalendar = async () => {
    try {
      const response = await fetch(`${url}/calendario_fertilizacion/get/cuartel/${currentUnit.id}`, {
        headers: headers,
        method: 'GET',
        mode: 'cors',
      });

      const data = await response.json();
      console.log(data)
      const products = data.productos;
      console.log(products);
      schedule.products = products;
      setScheduledProducts(products);
      const n = products.reduce((acc, product) => {
        return acc + product.product.N * product.product.quantity * monthDosisN[product.month];
      }, 0);
      const p = products.reduce((acc, product) => {
        return acc + product.product.P * product.product.quantity * monthDosisP[product.month];
      }, 0);
      const k = products.reduce((acc, product) => {
        return acc + product.product.K * product.product.quantity * monthDosisK[product.month];
      }, 0);
      const ca = products.reduce((acc, product) => {
        return acc + product.product.Ca * product.product.quantity * monthDosisCa[product.month];
      }, 0);
      const mg = products.reduce((acc, product) => {
        return acc + product.product.Mg * product.product.quantity * monthDosisMg[product.month];
      }, 0);
      setAppliedN(n);
      setAppliedP(p);
      setAppliedK(k);
      setAppliedCa(ca);
      setAppliedMg(mg);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (currentUnit.id) loadCalendar();
  }, [currentUnit]);

  useEffect(() => {
    /* setInterval(() => {
      loadCalendar();
    }, 3000); */
    loadCalendar();
    const url = window.location.href;
    const cuartelid = parseInt(url.split("/").pop())
    setCurrentUnit(cuartelesMap.find(cuartel => cuartel.id === cuartelid));
    if (!cuartelid){
      setCurrentUnit(cuartelesMap[0]);
    }
    console.log("cuartel",cuartelid);
    
    

  }, []);

  useEffect(() => {
    fetch(`${url}/carro_de_compras/get/cuartel/${currentUnit.id}`, {
      headers: headers,
      method: 'GET',
      mode: 'cors',
    })
      .then(response => response.json())
      .then(data => {
        data.data[0].productos = JSON.parse(data.data[0].productos);
        setOrderProducts(data.data[0].productos);
        const n = data.data[0].productos.reduce((acc, product) => {
          return acc + product.N * product.quantity;
        }, 0);
        const p = data.data[0].productos.reduce((acc, product) => {
          return acc + product.P * product.quantity;
        }, 0);
        const k = data.data[0].productos.reduce((acc, product) => {
          return acc + product.K * product.quantity;
        }, 0);
        const ca = data.data[0].productos.reduce((acc, product) => {
          return acc + product.Ca * product.quantity;
        }, 0);
        const mg = data.data[0].productos.reduce((acc, product) => {
          return acc + product.Mg * product.quantity;
        }, 0);
        setBuyedN(n);
        setBuyedP(p);
        setBuyedK(k);
        setBuyedCa(ca);
        setBuyedMg(mg);
      })
    //TODO: cambiar por cuartelmap o sectoresmap
    if (selectedUnits.length == 2) {

      fetch(`${url}/calculos/cuartel/${currentUnit.id}`, {
        headers: headers,
        method: 'GET',
        mode: 'cors',
      }).then(res => res.json())
        .then(res => {
          if (res.data.length > 0) {
            setSeasonValues({
              limite_n: res.data[0].limite_n,
              limite_p: res.data[0].limite_p,
              limite_k: res.data[0].limite_k,
              limite_ca: res.data[0].limite_ca,
              limite_mg: res.data[0].limite_mg
            });
          }
        })
    }
  }, [currentUnit]);

  const handleExportClick = () => {
    const csvData = schedule.getCSVData();
    const monthOrder = ['Octubre', 'Noviembre', 'Diciembre', 'Enero', 'Febrero', 'Marzo'];
    const sortedData = csvData.sort((a, b) => {
      return monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month);
    });
    const workSheet = XLSX.utils.json_to_sheet(sortedData); // use sortedData instead of csvData
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Productos');
    XLSX.writeFile(workBook, 'productos.xlsx');

    alert("Programa guardado. redireccionando...");
    setTimeout(() => {
      window.location.href = "/dashboard/app";
    }, 1000);
  }
  const handlefulfilled = (month, productId, fulfilled) => {
    setScheduledProducts(schedule.modifyProduct(month, productId, fulfilled));
  }

  const handleSaveCalendar = () => {
    const token = localStorage.getItem('jwt');
    const headers = {
      auth: token,
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    };
    const data = JSON.stringify(schedule.products)

    //const data = schedule.products;

    fetch(`${url}/calendario_fertilizacion/post/cuartel/${currentUnit.id}`, {
      headers: headers,
      method: 'POST',
      mode: 'cors',
      body: data
    })
      .then(response => response.json())

    alert(`Calendario guardado para las unidades cuartel ${currentUnit.id}. redireccionando...`);
    setTimeout(() => {
      document.location.href = "/dashboard/app";
    }, 1000);
  }


  if (!localStorage.getItem('jwt')) {
    navigate('/login', { replace: true });
  }
  const token = localStorage.getItem('jwt');
  const headers = {
    auth: token,
  };

  const handleRemove = (month, product) => {
    setScheduledProducts(schedule.removeProduct(month, product.id));

    const n = schedule.products.reduce((acc, product) => {
      return acc - product.product.N * product.product.quantity * monthDosisN[product.month];
    }, 0);
    const p = schedule.products.reduce((acc, product) => {
      return acc - product.product.P * product.product.quantity * monthDosisP[product.month];
    }, 0);
    const k = schedule.products.reduce((acc, product) => {
      return acc - product.product.K * product.product.quantity * monthDosisK[product.month];
    }, 0);
    const ca = schedule.products.reduce((acc, product) => {
      return acc - product.product.Ca * product.product.quantity * monthDosisCa[product.month];
    }, 0);
    const mg = schedule.products.reduce((acc, product) => {
      return acc - product.product.Mg * product.product.quantity * monthDosisMg[product.month];
    }, 0);
    setAppliedN(n);
    setAppliedP(p);
    setAppliedK(k);
    setAppliedCa(ca);
    setAppliedMg(mg);


  }

  const handleAddMore = (month, product) => {
    setScheduledProducts(
      schedule.addProduct(month, product)
    );

    const n = schedule.products.reduce((acc, product) => {
      return acc + product.product.N * product.product.quantity * monthDosisN[product.month];
    }, 0);
    const p = schedule.products.reduce((acc, product) => {
      return acc + product.product.P * product.product.quantity * monthDosisP[product.month];
    }, 0);
    const k = schedule.products.reduce((acc, product) => {
      return acc + product.product.K * product.product.quantity * monthDosisK[product.month];
    }, 0);
    const ca = schedule.products.reduce((acc, product) => {
      return acc + product.product.Ca * product.product.quantity * monthDosisCa[product.month];
    }, 0);
    const mg = schedule.products.reduce((acc, product) => {
      return acc + product.product.Mg * product.product.quantity * monthDosisMg[product.month];
    }, 0);
    setAppliedN(n);
    setAppliedP(p);
    setAppliedK(k);
    setAppliedCa(ca);
    setAppliedMg(mg);


  }

  return (

    <Container maxWidth={false} >

      {/* map */}
      <Card variant="outlined" style={{ padding: "16px" }} >
        <Grid container spacing={2}>
          {/* Map controls */}
          <Grid item xs={12}>
            <Button
              variant={'contained'}
              sx={{ color: 'white' }}
              style={{
                backgroundColor: 'rgb(81, 160, 154)',
                marginRight: '5px'
              }}
              onClick={() => setSelectedUnits(sectoresMap)}
            >
              Sectores
            </Button>
            <Button
              variant={'contained'}
              sx={{ color: 'white' }}
              style={{
                backgroundColor: 'rgb(81, 160, 154)',
                margin: '0px 5px'
              }}
              onClick={() => setSelectedUnits(cuartelesMap)}
            >
              Cuarteles
            </Button>
            <Button
              variant={'contained'}
              sx={{ color: 'white' }}
              style={{
                backgroundColor: 'rgb(81, 160, 154)',
                margin: '0px 5px'
              }}
              onClick={() => {
                setCenter({ ...center })
              }}
            >
              Centrar
            </Button>
          </Grid>
          {/* / Map controls */}

          {/* Map canvas */}
          <Grid item xs={6}>
            <Map
              listaUnidades={selectedUnits}
              center={center}
              onSelectedUnidad={handleSelectedUnidad}
            ></Map>
          </Grid>
          {/* /Map canvas */}
          {/* Chart Canvas */}
          <Grid item xs={6}>
            <canvas ref={chartRef} />
          </Grid>



        </Grid>
      </Card>
      {/* /map */}
      <Card variant="outlined" style={{ padding: "16px" }} >
        <div>
          <Typography variant="h4" gutterBottom component="div">
            Calendario Anual de Fertilización
          </Typography>
          <Typography variant="h6" gutterBottom component="div">
            {/* TODO: cambiar nombre del cuartel */}
            Seleccionado : {currentUnit.nombre === "Cuartel 26" ? "Cuartel 2" : "Cuartel 1"}
          </Typography>
          <Typography variant="h6" gutterBottom component="div">
            Etapa Fenológica actual : {getStage(step).Nombre}
          </Typography>
          <Typography variant="h6" gutterBottom component="div">

          </Typography>

        </div>
        <Stack direction="row" justifyContent="space-between" spacing={3}>
          {/* pop up */}
          <Popup
            trigger={
              <Button
                id="button-popup"
                style={{ backgroundColor: 'rgb(81, 160, 154)', color: 'white' }}
                variant="contained"
                onClick={() => {
                  <Popup trigger={<button> Trigger</button>} position="right center">

                  </Popup>
                }
                }
              >
                Seleccionar Etapa Fenológica actual
              </Button>}
            modal
            nested
          >

          </Popup>
          <Button
            id="button-export"
            style={{ backgroundColor: 'rgb(81, 160, 154)', color: 'white', textTransform: 'none' }}
            variant="contained"
            onClick={handleSaveCalendar}
          >
            Guardar calendario y exportar
          </Button>


        </Stack>

        {/* pop up */}
        <img src={Etapas} alt="Descripción de la imagen" style={{ width: '100%' }} />
        <Stack direction="row" justifyContent="center" spacing={3} >
          <Calendar
            months={months}
            scheduledProducts={scheduledProducts}
            orderProducts={orderProducts}
            handleRemove={handleRemove}
            handleAddMore={handleAddMore}
          ></Calendar>
        </Stack>

      </Card>
    </Container>


  )
}
