import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Autocomplete,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Box,
  Button
} from "@mui/material";
import { useParams } from "react-router-dom";
import dotenv from "dotenv";
dotenv.config();

const url = process.env.REACT_APP_BASE_URL;

function fixsacks(mineral, mineralValue) {
  var sacks = 0;
  if (Math.trunc(mineral / mineralValue) === 0 && mineral > 0) {
    sacks = 1;
  } else if (mineral % mineralValue >= mineralValue / 2) {
    sacks = Math.trunc(mineral / mineralValue) + 1;
  } else {
    sacks = Math.trunc(mineral / mineralValue);
  }
  return sacks;
}


export default function ProductCalculation({ setStep }) {
  const { idSector } = useParams();
  const [getProvider, setProvider] = useState([]);
  const [getProducts, setProducts] = useState([]);
  const [getSelectedNProduct, setSelectedNProduct] = useState([]);
  const [getSelectedPProduct, setSelectedPProduct] = useState(0);
  const [getSelectedKProduct, setSelectedKProduct] = useState(0);
  const [getFilteredProduct, setFilteredProduct] = useState([]);
  const N = localStorage.getItem("NSeasonValue");
  const P = localStorage.getItem("PSeasonValue");
  const K = localStorage.getItem("KSeasonValue");
  const Ca = localStorage.getItem("CaSeasonValue");
  const Mg = localStorage.getItem("MgSeasonValue");


  useEffect(() => {
    const token = localStorage.getItem("jwt");
    fetch(`${url}/proveedores`, {
      headers: {
        auth: token
      }
    })
      .then(val => val.json())
      .then(response => setProvider(response.data))
    fetch(`${url}/productos`, {
      headers: {
        auth: token
      }
    })
      .then(val => val.json())
      .then(response => setProducts(response.data))
  }, [])

  const handleChange = (e) => {
    let listProducts = getProducts.filter((product) => product.proveedor_id === e.target.value);
    setFilteredProduct(listProducts)
  }

  return (
    <Box sx={{ pr: "2rem" }}>
      <FormControl sx={{ marginBottom: '1rem' }} fullWidth>
        <InputLabel sx={{ width: '70%' }} id="demo-simple-select-label">Proveedor</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          sx={{ width: '70%' }}
          id="demo-simple-select"
          label="Proveedores"
          onChange={(e) => handleChange(e)}
        >
          {getProvider &&
            getProvider.map((val, key) => <MenuItem key={key} value={val.id}>{val.nombre}</MenuItem>)
          }
        </Select>
      </FormControl>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                N
              </TableCell>
              <TableCell align="right">

                <Autocomplete
                  disablePortal
                  id="product-combo-autocomplete"
                  options={getFilteredProduct.filter(product => product.tipo_producto_id === 1)}
                  renderInput={(params) => <TextField {...params} label="Producto" />}
                  renderOption={(props, product) => <MenuItem {...props} key={product.id} value={product.id}>{product.nombre}</MenuItem>} // Muestra el nombre en las opciones desplegables
                  getOptionLabel={(params) => params.nombre} // Obtiene el nombre para la etiqueta de la entrada
                  onChange={(e) => {
                    const entrada = e.target.value;
                    const producto = getFilteredProduct.filter(product => product.id === entrada);
                    const nValue = producto[0].n;
                    const sacks = fixsacks(N, nValue);
                    const units = sacks * nValue;
                    const cost = sacks * producto[0].precio;
                    setSelectedNProduct([sacks, units, cost, producto[0].id]);
                  }
                  }

                />
              </TableCell>
              <TableCell align="right"> Total sacos : {getSelectedNProduct[0]}   </TableCell>
              <TableCell align="right"> Costo : {getSelectedNProduct[2]} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                P
              </TableCell>
              <TableCell align="right">

                <Autocomplete
                  disablePortal
                  id="product-combo-autocomplete"
                  options={getFilteredProduct.filter(product => product.tipo_producto_id === 2)}
                  renderInput={(params) => <TextField {...params} label="Producto" />}
                  renderOption={(props, product) => <MenuItem {...props} key={product.id} value={product.id}>{product.nombre}</MenuItem>} // Muestra el nombre en las opciones desplegables
                  getOptionLabel={(params) => params.nombre} // Obtiene el nombre para la etiqueta de la entrada
                  onChange={(e) => {
                    const entrada = e.target.value;
                    const producto = getFilteredProduct.filter(product => product.id === entrada);
                    const pValue = producto[0].p;
                    const sacks = fixsacks(P, pValue);
                    const units = sacks * pValue;
                    const cost = sacks * producto[0].precio;
                    setSelectedPProduct([sacks, units, cost, producto[0].id]);

                  }
                  }
                />
              </TableCell>

              <TableCell align="right"> Total sacos : {getSelectedPProduct[0]}   </TableCell>
              <TableCell align="right">Costo : {getSelectedPProduct[2]} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                K
              </TableCell>
              <TableCell align="right">

                <Autocomplete
                  disablePortal
                  id="product-combo-autocomplete"
                  options={getFilteredProduct.filter(product => product.tipo_producto_id === 3)}
                  renderInput={(params) => <TextField {...params} label="Producto" />}
                  renderOption={(props, product) => <MenuItem {...props} key={product.id} value={product.id}>{product.nombre}</MenuItem>} // Muestra el nombre en las opciones desplegables
                  getOptionLabel={(params) => params.nombre} // Obtiene el nombre para la etiqueta de la entrada
                  onChange={(e) => {
                    const entrada = e.target.value;
                    const producto = getFilteredProduct.filter(product => product.id === entrada);
                    const kValue = producto[0].k;
                    const sacks = fixsacks(K, kValue);
                    const units = sacks * kValue;
                    const cost = sacks * producto[0].precio;
                    const suma = getSelectedNProduct[2] + getSelectedPProduct[2] + cost;
                    const totalSacks = getSelectedNProduct[0] + getSelectedPProduct[0] + sacks;
                    setSelectedKProduct([sacks, units, cost, suma, totalSacks, producto[0].id]);

                  }
                  }
                />
              </TableCell>
              <TableCell align="right"> Total sacos : {getSelectedKProduct[0]}   </TableCell>
              <TableCell align="right">Costo : {getSelectedKProduct[2]} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" align="right">
              </TableCell>
              <TableCell scope="row" align="right">
              </TableCell>
              <TableCell scope="row" align="right">
                Total Sacos: {getSelectedKProduct[4]}
              </TableCell>
              <TableCell scope="row" align="right">
                Costo Total: {getSelectedKProduct[3]}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        size="large"
        style={{ float: "right" }}
        sx={{
          mt: 1.5, backgroundColor: "rgb(81, 160, 154)",
          "&:hover": { background: "rgba(81, 160, 154,0.45)", color: "white" },
        }}
        onClick={() => {
          const token = localStorage.getItem("jwt");
          const data = {
            calculos: [
              {
                sacos: getSelectedNProduct[0],
                costo: getSelectedNProduct[2],
                producto_id: getSelectedNProduct[3],
              },
              {
                sacos: getSelectedPProduct[0],
                costo: getSelectedPProduct[2],
                producto_id: getSelectedPProduct[3],
              },
              {
                sacos: getSelectedKProduct[0],
                costo: getSelectedKProduct[2],
                producto_id: getSelectedKProduct[5],
              },
            ],
          };


          fetch(`${url}/calculos/sector/${idSector}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              auth: token,
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((responseData) => {
              console.log(responseData);
            })
            .catch((error) => {

              console.error("Error:", error);
            });

        }}
      >
        Guardar
      </Button>
    </Box>
  )
}
