import React from 'react';

import {
  Button,
  MenuItem,
  Select
} from '@mui/material';


export const TaskAdd = ({ month, orderProducts, handleAddMore }) => {
  const [selectedProduct, setSelectedProduct] = React.useState('');

  return (
    <div className='task-add' >
      <Select
        label="Agregar producto"
        value={selectedProduct}
        onChange={(event) => { setSelectedProduct(event.target.value) }}
        style={{ maxWidth: '100px' }}
      >
        {
          orderProducts.length > 0 && orderProducts.map((product) => <MenuItem key={product.id} value={product}>+ {product.Nombre}</MenuItem>)
        }
      </Select>
      <Button variant="outlined" onClick={() => { handleAddMore(month, selectedProduct) }} >+</Button>
    </div>
  );
}