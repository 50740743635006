import React, { useState } from 'react';
import { Draggable } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import { GripVertical } from 'lucide-react';

const formatDate = (dateString) => {
  if (!dateString) return ''; // Manejar casos donde dateString es null o undefined
  return dateString.split('T')[0];
};

const formatPriority = (state) => {
  if (parseInt(state) === 0 || state === "" || state === "Sin asignar") {
    return "Sin asignar";
  } else if (parseInt(state) === 1) {
    return "Bajo";
  } else if (parseInt(state) === 2) {
    return "Medio";
  } else {
    return "Alto";
  }
};

const WhatsappTask = ({ task, index, handleDelete }) => {
  const [contextMenu, setContextMenu] = useState(null);

  const handleRightClick = (e) => {
    e.preventDefault();
  
    // Calcula las coordenadas relativas al contenedor del componente
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
  
    setContextMenu({
      mouseX: x,
      mouseY: y,
    });
  };

  const handleCloseMenu = () => {
    setContextMenu(null);
  };

  return (
    <Draggable key={task.id} draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`relative bg-gray-50 p-3 rounded-lg ${snapshot.isDragging ? 'border-2 border-blue-500' : ''}`}
          onContextMenu={handleRightClick}
          onClick={handleCloseMenu}
        >
          <div {...provided.dragHandleProps} className="cursor-move">
            <GripVertical className="w-4 h-4 mb-2 text-gray-400" />
          </div>
          <div className="space-y-1 text-base">
            <div><strong>Título:</strong> {task.titulo}</div>
            <div><strong>Prioridad:</strong> {formatPriority(task.prioridad)}</div>
            <div><strong>Persona:</strong> {task.empleado}</div>
            <div><strong>Fecha:</strong> {formatDate(task.fecha)}</div>
          </div>
          {contextMenu && (
            <div
              className="absolute z-10 bg-white border rounded shadow-lg"
              style={{
                top: `${contextMenu.mouseY}px`,
                left: `${contextMenu.mouseX}px`,
              }}
            >
              <button
                className="block w-full text-left px-4 py-2 text-base text-red-600 hover:bg-red-100"
                onClick={() => {
                  handleDelete(task);
                  handleCloseMenu();
                }}
              >
                Borrar Tarea
              </button>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default WhatsappTask;
