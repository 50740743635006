'use client'

import React, { useEffect, useState } from 'react'
import { Draggable, Droppable } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import { GripVertical, Edit2, Check, Trash2, ChevronDown, ChevronUp, Download, Edit } from 'lucide-react'
import Task from './Task'
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2';
import { send_message } from '../utlis/websocket';

const url = process.env.REACT_APP_BASE_URL;


const statusOptions = [
  { value: 'Pendiente', db: 0, color: 'bg-yellow-500' },
  { value: 'En progreso', db: 1, color: 'bg-orange-500' },
  { value: 'Hecho', db: 2, color: 'bg-green-500' },
]

const priorityOptions = [
  { value: 'Alto', db: 3, color: 'bg-red-500' },
  { value: 'Medio', db: 2, color: 'bg-yellow-500' },
  { value: 'Bajo', db: 1, color: 'bg-blue-500' },
]
const formatDate = (dateString) => {
  if (!dateString) return ''; // Manejar casos donde dateString es null o undefined
  return dateString.split('T')[0];
}
const cleanDetalleTask = (task) => {
  const detalleJson = JSON.parse(task.detalle);
  console.log("detalle", detalleJson);
  return detalleJson;
}

const handleDownload = (process, columns) => {
  
  const header = columns.map(column => column.titulo);

  console.log("process", process);
  const rows = process.tasks.map(task => {
    let detalle = cleanDetalleTask(task);
    detalle["fecha"] = formatDate(task.fecha);
    const row = columns.map(column => {
      if (column.titulo === "Fecha"){
        return formatDate(task.fecha);
      }
      return detalle[column.titulo] || "-";
    });

    // Agregar campos adicionales de task
    row.push(task.status || "-");
    row.push(task.empleado || "-");

    return row;
  });

  // Agregar "Estado", "Persona", "Fecha" y "Prioridad" al encabezado
  header.push("Estado");
  header.push("Persona");


  console.log("rows", rows);

  // Combinar el encabezado y las filas de datos en un solo array de arrays
  const data = [header, ...rows];

  // Crea una hoja de trabajo a partir de los datos
  const worksheet = XLSX.utils.aoa_to_sheet(data);

  // Crea un libro de trabajo y agrega la hoja de trabajo
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, process.title);

  // Genera un archivo Excel
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Crea un Blob a partir del buffer
  const file = new Blob([excelBuffer], { type: 'application/octet-stream' });

  // Crea un enlace para descargar el archivo
  const element = document.createElement("a");
  element.href = URL.createObjectURL(file);
  element.download = `${process.title}.xlsx`;
  document.body.appendChild(element); // Requerido para que funcione en Firefox
  element.click();
  document.body.removeChild(element); // Limpia el DOM
};



const Process = ({
  process,
  index,
  toggleStatusDropdown,
  togglePriorityDropdown,
  toggleEmployeesDropdown,
  updateTaskStatus,
  updateTaskPriority,
  updateTaskEmployee,
  openStatusDropdown,
  openPriorityDropdown,
  openEmployeesDropdown,
  toggleEditMode,
  updateProcessTitle,
  finishEditingProcessTitle,
  handleKeyDown,
  deleteProcess,
  addNewTask,
  updateNewTaskContent,
  employees,
  addNewProcess,
  getBoards,
  updateTaskTitle,
  connection
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isDraggingOver, setIsDraggingOver] = useState(false)

  const configuration = process["configuracion"] ? JSON.parse(process["configuracion"]) : {}
  const order = configuration["orden"] || []

  const [columnsQuantity, setColumnsQuantity] = useState(0)
  const [columns, setColumns] = useState([])

  const getColumns = (process) => {
    fetch(`${url}/columnas/proceso/${process.id}`)
      .then(response => response.json())
      .then(data => {
        setColumns(data.data)
        setColumnsQuantity(data.data.length + 4)

        return data.data
      })
      .catch(error => console.error(error))
  }

    const addColumn = (process) => {
      Swal.fire({
        title: 'Nueva columna',
        html: `
        <input id="swal-input1" placeholder="Título..." class="swal2-input">
        <textarea id="swal-input2" placeholder="¡Cuéntanos de qué trata esta columna!" style="height: 100px;" class="swal2-input"></textarea>
        <div class="select-checkbox-container" style="display: flex; gap: 10px; align-items: center;">
            <select id="swal-input3" class="swal2-select">
                <option value="integer">Número</option>
                <option value="fecha-string">Fecha</option>
                <option value="photo-string">Foto</option>
                <option value="string">Texto</option>
                <option value="integer-array">Lista de Números</option>
                <option value="string-array">Lista de Palabras</option>
            </select>
            <div class="checkbox-container" style="display: flex; align-items: center; gap: 5px;">
                <input type="checkbox" id="swal-input4">
                <label for="swal-input4">Obligatorio?</label>
            </div>
        </div>
    `,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'Agregar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'swal-custom-confirm', // Clase personalizada para el botón confirmar
          cancelButton: 'swal-custom-cancel',   // Clase personalizada para el botón cancelar
        },
        preConfirm: () => {
          const title = document.getElementById('swal-input1').value;
          const description = document.getElementById('swal-input2').value;
          const type = document.getElementById('swal-input3').value;
          const required = document.getElementById('swal-input4').checked ? 1 : 0;
  
          if (!title) {
            Swal.showValidationMessage('El título es obligatorio');
          }

          if (type === 'fecha-string' && !title.toLowerCase().includes('fecha')) {
            Swal.showValidationMessage('El título debe contener la palabra "fecha" para el tipo "fecha"');
            return false;
          }
          if (type === 'photo-string' && !title.toLowerCase().includes('foto')) {
            Swal.showValidationMessage('El título debe contener la palabra "foto" para el tipo "foto"');
            return false;
          }
  
          return { title, description, type, required };
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const { title, description, type, required } = result.value;
          fetch(`${url}/columnas/add/${process.id}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ titulo: title, descripcion: description, tipo: type, requerido: required }),
          })
            .then(response => response.json())
            .then(data => {

            })
            .catch(error => console.error(error))
  
          setColumnsQuantity(columnsQuantity + 1);
          setColumns([...columns, { titulo: title, descripcion: description, tipo: type, requerido: required }]);
        } 
      });
      //getColumns(process)
      send_message(connection, "refresh:columns")
    };
  
    const editColumn = (process, column) => {
      if (column.tipo === 'fecha-string') { return; }
      Swal.fire({
        title: 'Editar columna',
        html: `
          <input id="swal-input1" class="swal2-input" placeholder="Título" style="margin-bottom: 10px; padding: 10px; border-radius: 5px;" value="${column.titulo}">
           <textarea id="swal-input2" class="swal2-input" placeholder="Descripción" style="height: 100px;">${column.descripcion}</textarea>
          <select id="swal-input3" class="swal2-input" style="margin-bottom: 10px; padding: 10px; border-radius: 5px;">
            <option value="integer" ${column.tipo === 'integer' ? 'selected' : ''}>Número</option>
            <option value="fecha-string" ${column.tipo === 'fecha-string' ? 'selected' : ''}>Fecha</option>
            <option value="photo-string" ${column.tipo === 'photo-string' ? 'selected' : ''}>Foto</option>
            <option value="string" ${column.tipo === 'string' ? 'selected' : ''}>Texto</option>
            <option value="integer-array" ${column.tipo === 'array' ? 'selected' : ''}>Lista de Números</option>
            <option value="string-array" ${column.tipo === 'array' ? 'selected' : ''}>Lista de Palabras</option>
          </select>
          <div class="checkbox-container" style="display: flex; align-items: center; gap: 5px;">
                <input type="checkbox" id="swal-input4" ${column.requerido ? "checked" : ""}>
                <label for="swal-input4">Obligatorio?</label>
            </div>
        `,
        focusConfirm: false,
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: 'Guardar',
        denyButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'swal-custom-confirm', // Clase personalizada para el botón confirmar
        },
        preConfirm: () => {
          const title = document.getElementById('swal-input1').value;
          const description = document.getElementById('swal-input2').value;
          const type = document.getElementById('swal-input3').value;
          const required = document.getElementById('swal-input4').checked ? 1 : 0;
  
          if (!title) {
            Swal.showValidationMessage('El título es obligatorio');
          }

          if (type === 'fecha-string' && !title.toLowerCase().includes('fecha')) {
            Swal.showValidationMessage('El título debe contener la palabra "fecha" para el tipo "fecha"');
            return false;
          }

          if (type === 'photo-string' && !title.toLowerCase().includes('foto')) {
            Swal.showValidationMessage('El título debe contener la palabra "foto" para el tipo "foto"');
            return false;
          }
  
          return { title, description, type, required };
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const { title, description, type, required } = result.value;
          fetch(`${url}/columnas/edit/${column.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ titulo: title, descripcion: description, tipo: type, requerido: required }),
          })
            .then(response => response.json())
            .then(data => {

            })
            .catch(error => console.error(error))
  
          const updatedColumns = columns.map(c => c.id === column.id ? { ...c, titulo: title, descripcion: description, tipo: type, requerido: required } : c);
          setColumns(updatedColumns);
          getBoards();
        } else if (result.isDenied) {
          Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              fetch(`${url}/columnas/delete/${column.id}`, {
                method: 'DELETE',
              })
                .then(response => response.json())
                .then(data => {

                })
                .catch(error => console.error(error))
  
              const updatedColumns = columns.filter(c => c.id !== column.id);
              
              setColumns(updatedColumns);
              setColumnsQuantity(columnsQuantity - 1);
              getBoards();
            }
          });
        }
      });
      send_message(connection, "refresh:columns")
    };
  
  useEffect(() => {
    if (process["tipo"] == 2) {
      getColumns(process)
      setColumnsQuantity(process.length)

    }
  }, [])

  const orderMap = new Map(order.map((id, index) => {
    const key = typeof id === 'number' ? id : id;
    return [key, index];
  }));

  const orderedTasks = process.tasks.sort((a, b) => {
    const idA = a.detalle ? `A${a.id}` : a.id;
    const idB = b.detalle ? `A${b.id}` : b.id;
    const orderA = orderMap.get(idA) !== undefined ? orderMap.get(idA) : Infinity;
    const orderB = orderMap.get(idB) !== undefined ? orderMap.get(idB) : Infinity;
    return orderA - orderB;
  });

  return (
    <Draggable key={process.id} draggableId={process.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`bg-white rounded-lg shadow-md p-4 ${snapshot.isDragging ? 'border-2 border-blue-500' : ''}`}
        >
          <div className="flex justify-between items-center mb-2">
            <div {...provided.dragHandleProps} className="flex items-center cursor-move">
              <GripVertical className="w-5 h-5 mr-2 text-gray-400" />
              {process.isEditing ? (
                <input
                  type="text"
                  value={process.title}
                  onChange={(e) => updateProcessTitle(process.id, e.target.value)}
                  onBlur={() => finishEditingProcessTitle(process.id)}
                  onKeyDown={(e) => handleKeyDown(e, process.id)}
                  className="font-semibold border rounded px-2 py-1"
                  autoFocus
                />
              ) : (
                <h2 className="text-lg font-semibold">{process.title}</h2>
              )}
              <button
                className="text-gray-600 hover:text-gray-900 ml-2"
                onClick={() => setIsCollapsed(!isCollapsed)}
                aria-expanded={!isCollapsed}
                aria-label={isCollapsed ? "Expandir proceso" : "Colapsar proceso"}
              >
                {isCollapsed ? <ChevronDown className="w-5 h-5" /> : <ChevronUp className="w-5 h-5" />}
              </button>
            </div>
            <div className="flex items-center space-x-2">
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => toggleEditMode(process.id)}
                aria-label={process.isEditing ? "Guardar cambios" : "Editar proceso"}
              >
                {process.isEditing ? <Check className="w-4 h-4" /> : <Edit2 className="w-4 h-4" />}
              </button>
              <button
                className="text-red-600 hover:text-red-800"
                onClick={() => deleteProcess(process.id)}
                aria-label="Eliminar proceso"
              >
                <Trash2 className="w-4 h-4" />
              </button>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => handleDownload(process,columns)}
                aria-label="Descargar proceso"
              >
                <Download className="w-4 h-4" />
              </button>

            </div>
          </div>
          {!isCollapsed && (
            <>
              {process["tipo"] == 1 && <Droppable droppableId={process.id} type="task">
                {(provided, snapshot) => {
                  // Actualizar el estado cuando el componente esté siendo arrastrado sobre él
                  if (snapshot.isDraggingOver !== isDraggingOver) {
                    setIsDraggingOver(snapshot.isDraggingOver);
                  }
                  return (
                    <div className={`overflow-x-auto droppable-animate ${isDraggingOver ? 'droppable-hover' : 'droppable-default'}`} style={{ maxHeight: '400px', overflowY: 'auto' }} {...provided.droppableProps} ref={provided.innerRef}>
                      <table className="border-collapse fixed-table">
                        <thead>
                          <tr className="bg-gray-100">
                            <th className="border p-2 w-4xs text-base"></th>
                            <th className="border p-2 w-1xs text-base sticky bg-gray-100 z-10" style={{ position: 'sticky', left: 0, zIndex: 10 }}>Título</th>
                            <th className="border p-2 w-3xs text-base">Númerico</th>
                            <th className="border p-2 w-3xs text-base">Tipo</th>
                            <th className="border p-2 w-3xs text-base">Ubicación</th>
                            <th className="border p-2 w-3xs text-base">Estado</th>
                            <th className="border p-2 w-3xs text-base">Prioridad</th>
                            <th className="border p-2 w-3xs text-base">Persona</th>
                            <th className="border p-2 w-3xs text-base">Fecha Inicio</th>
                            <th className="border p-2 w-3xs text-base">Fecha Término</th>
                            <th className="border p-2 w-3xs text-base">Imagen</th>
                          </tr>
                        </thead>
                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                          {orderedTasks.length > 0 ? (
                            orderedTasks.map((task, index) => (
                              <Task
                                key={task.issue_id ? `A${task.id}` : task.id}
                                task={task}
                                index={index}
                                processId={process.id}
                                statusOptions={statusOptions}
                                priorityOptions={priorityOptions}
                                toggleStatusDropdown={toggleStatusDropdown}
                                togglePriorityDropdown={togglePriorityDropdown}
                                toggleEmployeesDropdown={toggleEmployeesDropdown}
                                updateTaskStatus={updateTaskStatus}
                                updateTaskPriority={updateTaskPriority}
                                updateTaskEmployee={updateTaskEmployee}
                                openStatusDropdown={openStatusDropdown}
                                openPriorityDropdown={openPriorityDropdown}
                                openEmployeesDropdown={openEmployeesDropdown}
                                employees={employees}
                                getBoards={getBoards}
                                updateTaskTitle={updateTaskTitle}
                                process={process}
                                columns={columns}
                              />
                            ))
                          ) : (
                            <tr>
                              <td colSpan="11" className="text-center p-4 w-full">
                                Sin tareas
                              </td>
                            </tr>
                          )}
                        </tbody>
                        {isDraggingOver && (
                          <tr className="draggable-placeholder-row">
                            <td colSpan={11} className="draggable-placeholder"></td>
                          </tr>
                        )}
                      </table>
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>}
              {process["tipo"] == 2 && <Droppable droppableId={process.id} type="task">
                {(provided, snapshot) => {
                  // Actualizar el estado cuando el componente esté siendo arrastrado sobre él
                  if (snapshot.isDraggingOver !== isDraggingOver) {
                    setIsDraggingOver(snapshot.isDraggingOver);
                  }
                  return (
                    <div className={`droppable-animate ${isDraggingOver ? 'droppable-hover' : 'droppable-default'}`} style={{ minHeight: '150px' ,maxHeight: '400px', overflowX: 'auto', overflowY: 'auto' }} {...provided.droppableProps} ref={provided.innerRef}>
                      <table className="border-collapse fixed-table overflow-x-auto">
                        <thead >
                          <tr className="bg-gray-100">
                            <th className="border p-2 w-4xs text-base" style={{ width: '30px' }}></th>
                            {columns.length > 0 &&
                              columns.map((column, index) => (
                                <th key={index} className="relative border p-2 text-base whitespace-nowrap group cursor-pointer" onClick={() => editColumn(process, column)} >
                                  {column.requerido == 1 && <span className="text-red-500">* </span>}{ column.titulo} {/* arreglar requerido*/ }
                                  {/* Tooltip */}
                                  {/* <div className="tooltip absolute z-10 left-0 bottom-full mt-1 w-max bg-gray-800 text-white text-sm rounded p-2 opacity-0 group-hover:opacity-100 group-hover:translate-y-1 transition-all">
                                    {column.descripcion}
                                  </div> */}
                                </th>
                              ))}
                            <th className="border p-2 w-3xs text-base">Estado</th>
                            <th className="border p-2 w-3xs text-base">Persona</th>
                            <th className="border p-3 text-lg w-3xs bg-[#f3f4f6]">
                              <button className="w-full h-full" onClick={() => addColumn(process)}>+</button>
                            </th>
                          </tr>
                        </thead>
                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                          {orderedTasks.length > 0 ? (
                            orderedTasks.map((task, index) => (
                              <Task
                                key={task.issue_id ? `A${task.id}` : task.id}
                                task={task}
                                index={index}
                                processId={process.id}
                                statusOptions={statusOptions}
                                priorityOptions={priorityOptions}
                                toggleStatusDropdown={toggleStatusDropdown}
                                togglePriorityDropdown={togglePriorityDropdown}
                                toggleEmployeesDropdown={toggleEmployeesDropdown}
                                updateTaskStatus={updateTaskStatus}
                                updateTaskPriority={updateTaskPriority}
                                updateTaskEmployee={updateTaskEmployee}
                                openStatusDropdown={openStatusDropdown}
                                openPriorityDropdown={openPriorityDropdown}
                                openEmployeesDropdown={openEmployeesDropdown}
                                employees={employees}
                                getBoards={getBoards}
                                updateTaskTitle={updateTaskTitle}
                                process={process}
                                columns={columns}
                              />
                            ))
                          ) : (
                            <tr>
                              <td colSpan={columnsQuantity} className="text-center p-4 w-full">
                                Sin tareas
                              </td>
                            </tr>
                          )}
                        </tbody>
                        {isDraggingOver && (
                          <tr className="draggable-placeholder-row">
                            <td colSpan={11} className="draggable-placeholder"></td>
                          </tr>
                        )}
                      </table>
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>}
              {process["tipo"] == 1 && 
              <div className="mt-2 flex space-x-2">
                <input
                  type="text"
                  placeholder="+ Añadir Tarea"
                  value={process.newTaskContent}
                  onChange={(e) => updateNewTaskContent(process.id, e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && addNewTask(process.id)}
                  className="flex-grow border rounded px-2 py-1"
                />
                <button
                  onClick={() => addNewTask(process.id)}
                  className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
                >
                  Añadir
                </button>
              </div>}
            </>
          )}
        </div>
      )}
    </Draggable>
  )
}

export default Process