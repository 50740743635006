import { Button } from "./Button";

export default function Botones({ handleCargarExcel, handleDescargar, fileInputRef }) {
  return (
    <div className="flex justify-end mb-6">
      <Button  onClick={() => fileInputRef.current.click()} className="bg-black hover:bg-gray-800 text-white font-bold mx-1 py-2 px-4 rounded text-xs">
        Cargar Excel
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleCargarExcel}
          className="hidden"
          accept=".xlsx, .xls"
        />
      </Button>
      <Button disabled={true} onClick={handleDescargar} className="bg-gray-500 text-white font-bold mx-1 py-2 px-4 rounded text-xs">
        Descargar
      </Button>
    </div>
  )
}