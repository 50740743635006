import { Card, Box, Typography, Table, TableCell, TableBody, TableRow, TableHead, TableSortLabel, Accordion, AccordionSummary, AccordionDetails, Dialog, DialogContent, Chip, Select, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import dotenv from 'dotenv';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { visuallyHidden } from '@mui/utils';

dotenv.config();
const url = process.env.REACT_APP_BASE_URL;

const importanceOrder = {
    'Urgente': 3,
    'Medio': 2,
    'Bajo': 1,
    'Sin asignar': 0,
};

function descendingComparator(a, b, orderBy) {
    if (orderBy === 'estado') {
        if (importanceOrder[b[orderBy]] < importanceOrder[a[orderBy]]) {
            return -1;
        }
        if (importanceOrder[b[orderBy]] > importanceOrder[a[orderBy]]) {
            return 1;
        }
    } else if (orderBy === 'fecha') {
        if (new Date(b[orderBy]) < new Date(a[orderBy])) {
            return -1;
        }
        if (new Date(b[orderBy]) > new Date(a[orderBy])) {
            return 1;
        }
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
    }
    return 0;
};

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export default function IngresosLCP() {
    if (!localStorage.getItem('jwt')) {
        window.location.href = "/login";
    }
    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState({});
    const [issues, setIssues] = useState([]);
    const [filteredIssues, setFilteredIssues] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('estado');

    const jwt = localStorage.getItem('jwt');
    const data = jwt.split('.')[1];
    const user = JSON.parse(atob(data));
    const Empresa_id = user.Empresa_id;
    const Empresa_name = user.empresa;
    const User_id = user.User_id;

    const handleEmployee = (e) => {
        if (e.target.value === 0) {
            setEmployee(0);
            setFilteredIssues(issues);
            return;
        }
        setEmployee(e.target.value);
    };

    function setupWebSocket() {
        const uri = 'wss://websocket-eqjlrpwjoa-uc.a.run.app';
        const connection = new WebSocket(uri);
        connection.addEventListener('open', () => {
            console.log('Connected to the WebSocket server');
        });

        connection.addEventListener('message', (event) => {
            const data = event.data;
            console.log('Received the defined message:', data);
            const refreshed_id = data.split(':')[1].trim();
            console.log("refreshed_id", refreshed_id);
            if (parseInt(Empresa_id) === parseInt(refreshed_id)) {
                fetch(`${url}/registrosLCP`)
                    .then(response => response.json())
                    .then(data => {
                        const newIssues = data.filter(issue => issue.tipo_registro === "Ingreso");
                        setIssues(newIssues);
                        setFilteredIssues(newIssues);
                    })
                    .catch(error => {
                        console.error('Error al obtener los datos:', error);
                    });
            }
        });

        connection.addEventListener('close', () => {
            setupWebSocket();
        });
    };

    useEffect(() => {
        setupWebSocket();
        fetch(`${url}/usuarios/empresa/${User_id}`)
            .then(response => response.json())
            .then(data => {
                setEmployees(data.usuarios);
                setEmployee(0);
            })
            .catch(error => {
                console.error('Error al obtener los datos:', error);
            });
        fetch(`${url}/registrosLCP`)
            .then(response => response.json())
            .then(data => {
                const newIssues = data.filter(issue => issue.tipo_registro === "Ingreso");
                setIssues(newIssues);
                setFilteredIssues(newIssues);
            })
            .catch(error => {
                console.error('Error al obtener los datos:', error);
            });
    }, []);

    useEffect(() => {
        if (employee === 0) {
            setFilteredIssues(issues);
            return;
        }
        const newIssues = issues.filter(issue => issue.User_id === employee);
        setFilteredIssues(newIssues);
    }, [employee, issues]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const renderTable = (issues) => {
        return issues.map((issue) => {
            const isExpanded = expanded === issue.id;
            return (
                <TableRow key={issue.id}>
                    <TableCell>{issue.empleado}</TableCell>
                    <TableCell>{issue.detalle}</TableCell>
                    <TableCell>{fixDate(issue.fecha)}</TableCell>
                    <TableCell>{issue.origen}</TableCell>
                    <TableCell>{issue.cantidad}</TableCell>
                    {/* <TableCell>{issue.precio_de_compra}</TableCell> */}
                    <TableCell sx={{ width: '30%' }}>
                        {issue.url ? (
                            <Accordion expanded={isExpanded} onChange={() => handleAccordionChange(issue.id)}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    {!isExpanded && "(click para más detalles)"}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <img
                                        src={issue.url}
                                        alt="imagen"
                                        style={{ width: '30%', cursor: 'pointer' }}
                                        onClick={() => handleImageClick(issue.url)}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        ) : (
                            <Typography>No hay imagen disponible</Typography>
                        )}
                    </TableCell>
                </TableRow>
            )
        });
    };

    const handleAccordionChange = (panel) => {
        setExpanded(expanded === panel ? false : panel);
    };

    const handleImageClick = (url) => {
        setSelectedImage(url);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImage(null);
    };

    const fixDate = (oldDate) => {
        const date = new Date(oldDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <Card sx={{ px: 2, py: 1.5, height: "95%" }}>
            <Box sx={{ p: 3, display: 'flex', flexDirection: 'row' }} justifyContent="space-between">
                <Typography variant="h4" sx={{ mb: 3 }}>
                    Ingresos La ciudad posible
                </Typography>
                <Select sx={{marginRight: 2}} id="employees" value={employee} onChange={e => handleEmployee(e)}>
                    <MenuItem value={0}>Todos los empleados</MenuItem>
                    {employees.map((employee) => {return <MenuItem key={employee.id} value={employee.id}>{employee.name}</MenuItem>})}
                </Select>
            </Box>
            {
                filteredIssues.length > 0 ?
                    <Box sx={{ height: '80vh', overflowY: 'auto' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sortDirection={orderBy === 'empleado' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'empleado'}
                                            direction={orderBy === 'empleado' ? order : 'asc'}
                                            onClick={(e) => handleRequestSort(e, 'empleado')}
                                        >
                                            Empleado
                                            {orderBy === 'empleado' ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Detalle</TableCell>
                                    <TableCell sortDirection={orderBy === 'fecha' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'fecha'}
                                            direction={orderBy === 'fecha' ? order : 'asc'}
                                            onClick={(e) => handleRequestSort(e, 'fecha')}
                                        >
                                            Fecha
                                            {orderBy === 'fecha' ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Origen</TableCell>
                                    <TableCell>Cantidad (kilos)</TableCell>
                                    {/* <TableCell>Precio</TableCell> */}
                                    <TableCell>Imagen</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(filteredIssues, getComparator(order, orderBy)).map((issue) => (
                                    renderTable([issue])
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Typography variant="h3">No hay issues</Typography>
                    </div>
            }
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogContent>
                    {selectedImage && <img src={selectedImage} alt="imagen grande" style={{ maxWidth: '45%', height: 'auto', margin: '0 auto', display: 'block' }} />}
                </DialogContent>
            </Dialog>
        </Card>
    )
}
