import React, { useState, useEffect, useRef } from 'react'

const Select = ({ value, onChange, options, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={selectRef} className="relative">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full h-12 px-4 text-left bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-base"
      >
        <span className="block truncate">
          {value ? options.find(opt => opt === value) || `Tod${value === 'operacion' ? 'as' : 'os'}` : placeholder}
        </span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </span>
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
          {options.map((option) => (
            <div
              key={option}
              className={`${
                option === value ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
              } cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100`}
              onClick={() => {
                onChange(option)
                setIsOpen(false)
              }}
            >
              <span className={`block truncate ${option === value ? 'font-medium' : 'font-normal'}`}>
                {option || `Tod${option === 'operacion' ? 'as' : 'os'}`}
              </span>
              {option === value && (
                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default function Filtros({ filtros, handleFiltroChange, getUniqueValues, tipoMensajeGlobal, setTipomensajeGlobal, handleAplicarMensajeGlobal }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-6">
      {['ultima Ruta', 'vehiculo', 'turno', 'operacion', 'region'].map((campo) => (
        <div key={campo} className="flex flex-col">
          <label
            htmlFor={`filtro${campo.charAt(0).toUpperCase() + campo.slice(1)}`}
            className="mb-1 text-sm font-medium text-gray-700"
          >
            {campo.charAt(0).toUpperCase() + campo.slice(1)}
          </label>
          <Select
            id={`filtro${campo.charAt(0).toUpperCase() + campo.slice(1)}`}
            value={filtros[campo]}
            onChange={(value) => handleFiltroChange(campo, value)}
            options={getUniqueValues(campo)}
            placeholder={`Filtrar ${campo}`}
          />
        </div>
      ))}
    </div>
  )
}