import React, { useState, useRef, useEffect } from 'react'
import { Draggable } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import { GripVertical } from 'lucide-react'
import { Tooltip } from 'react-tooltip';
import Modal from './PhotoModal'
import "./Process.css";
import { use } from 'react';

const url = process.env.REACT_APP_BASE_URL;

const formatDate = (dateString) => {
  if (!dateString) return ''; // Manejar casos donde dateString es null o undefined
  return dateString.split('T')[0];
}

const fixPriority = (state) => {
  if (parseInt(state) === 0 || state === "" || state === "Sin asignar") {
    return "Sin asignar";
  } else if (parseInt(state) === 1) {
    return "Bajo";
  } else if (parseInt(state) === 2) {
    return "Medio"
  } else {
    return "Alto";
  }
};

const fixState = (state) => {
  if (parseInt(state) === 0) {
    return "Pendiente";
  } if (parseInt(state) === 1) {
    return "En progreso";
  } else {
    return "Hecho";
  }
};


function useClickOutside(ref, onClickOutside) {
  useEffect(() => {

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {

      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

const Task = ({
  task,
  index,
  processId,
  toggleStatusDropdown,
  togglePriorityDropdown,
  toggleEmployeesDropdown,
  updateTaskStatus,
  updateTaskPriority,
  updateTaskEmployee,
  openStatusDropdown,
  openPriorityDropdown,
  openEmployeesDropdown,
  statusOptions,
  priorityOptions,
  employees,
  getBoards,
  updateTaskTitle,
  process,
  columns
}) => {
  const [contextMenu, setContextMenu] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [title, setTitle] = useState(task?.task || task?.detalle || '');
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  }

  const [columnsNames, setColumnsNames] = useState([]);
  useEffect(() => {
    if (process.tipo == 2) {
      setColumnsNames(columns.map((column) => {

        return { titulo: column.titulo, tipo: column.tipo }
      }));
    }
  }, [columns]);

  const statusButtonRef = useRef(null);
  const priorityButtonRef = useRef(null);
  const employeeButtonRef = useRef(null);
  const titleButtonRef = useRef(null);
  const statusDropdownRef = useRef(null);
  const priorityDropdownRef = useRef(null);
  const employeeDropdownRef = useRef(null);
  const contextMenuRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  // Use the useClickOutside hook for each dropdown and context menu
  useClickOutside(statusDropdownRef, () => toggleStatusDropdown(null));
  useClickOutside(priorityDropdownRef, () => togglePriorityDropdown(null));
  useClickOutside(employeeDropdownRef, () => toggleEmployeesDropdown(null));
  useClickOutside(contextMenuRef, () => setContextMenu(null));

  const handleStatusButtonClick = () => {
    const rect = statusButtonRef.current.getBoundingClientRect();
    setDropdownPosition({ top: rect.bottom, left: rect.left });
    toggleStatusDropdown(task);
  };

  const handlePriorityButtonClick = () => {
    const rect = priorityButtonRef.current.getBoundingClientRect();
    setDropdownPosition({ top: rect.bottom, left: rect.left });
    togglePriorityDropdown(task);
  };

  const handleEmployeeButtonClick = () => {
    const rect = employeeButtonRef.current.getBoundingClientRect();
    setDropdownPosition({ top: rect.bottom, left: rect.left });
    toggleEmployeesDropdown(task);
  };

  const handleRightClick = (e) => {
    e.preventDefault();
    setContextMenu({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
    });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseMenu = () => {
    setContextMenu(null);
  };

  const handleDoubleClick = () => {
    setIsEditingTitle(true);
  };

  const handleKeyDown = (e) => {
    const taskType = task?.detalle ? "Registro" : "Tarea";
    if (e.key === 'Enter') {
      updateTaskTitle(processId, task.id, title, taskType);
      setIsEditingTitle(false);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  async function handleDelete() {
    const taskType = task?.detalle ? "Registro" : "Tarea";
    try {
      const response = await fetch(`${url}/${taskType === "Tarea" ? 'tareas' : 'issues'}/delete/${task.id}`, {
        method: 'DELETE',
        headers: {
          'mode': 'no-cors',
          'auth': localStorage.getItem('jwt'),
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      await getBoards();
    } catch (error) {
      console.error('Error deleting task:', error);
    }
    handleCloseMenu();
  }

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const cleanDetalleTask = (task) => {
    const detalleJson = JSON.parse(task.detalle);
    return detalleJson;
  }




  return (
    <>
      {process.tipo == 1 ? (
        <Draggable key={task.id} draggableId={task.issue_id ? `A${task.id}` : task.id} index={index}>
          {(provided, snapshot) => (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              className={`hover:bg-gray-50 ${snapshot.isDragging ? 'bg-blue-100' : ''}`}
              onContextMenu={handleRightClick}
              onClick={handleCloseMenu}
            >
              <td className="border p-2">
                <span {...provided.dragHandleProps}>
                  <GripVertical className="w-4 h-4 text-gray-400 cursor-move" />
                </span>
              </td>
              <td
                ref={titleButtonRef}
                className="border p-2 w-64 max-h-32 text-base sticky bg-white z-10 backdrop-filter backdrop-blur-sm"
                style={{ backgroundColor: 'rgba(255, 255, 255, 0.93)', left: '-2px' }}
                onDoubleClick={handleDoubleClick}
                onClick={toggleExpand}
              >
                {isEditingTitle ? (
                  <textarea
                    value={title}
                    onChange={handleTitleChange}
                    onKeyDown={handleKeyDown}
                    className="w-full p-1 h-16 text-base leading-tight resize"
                  />
                ) : (
                  isExpanded ? title : truncateText(title, 100)
                )}
              </td>
              <td className="border p-2 text-base">
                {task?.numerico === "null" || task?.numerico === null || task?.numerico === undefined ? " - " : task.numerico}
              </td>
              <td className="border p-2 text-base">
                {task?.detalle ? "Registro" : "Tarea"}
              </td>

              <td className="border p-2 text-base">
                {task?.ubicacion || "Sin ubicación"}
              </td>
              <td className="border p-2 relative text-base">
                <button
                  ref={statusButtonRef}
                  className={`w-full px-2 py-1 rounded ${statusOptions.find(option => option.db === task?.status || task?.done)?.color || 'bg-gray-300'}`}
                  onClick={handleStatusButtonClick}
                >
                  {fixState(task?.status) || fixState(task?.done)}
                </button>
                {openStatusDropdown === (task?.detalle ? `Registro-${task.id}` : `Tarea-${task.id}`) && (
                  <div
                    ref={statusDropdownRef}
                    className="fixed z-50 w-2xs bg-white border rounded shadow-lg text-base"
                    style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
                  >
                    {statusOptions.map((option) => (
                      <button
                        key={`status-${task.id}-${option.value}`}
                        className={`w-full px-2 py-1 hover:bg-gray-100 text-base ${option.color}`}
                        onClick={() => updateTaskStatus(processId, task.id, option.value, task?.detalle ? "Registro" : "Tarea")}
                      >
                        {option.value}
                      </button>
                    ))}
                  </div>
                )}
              </td>
              <td className="border p-2 relative text-base">
                <button
                  ref={priorityButtonRef}
                  className={`w-full px-2 py-1 rounded ${priorityOptions.find(option => option.db === parseInt(task?.priority) || task?.done)?.color || 'bg-gray-300'}`}
                  onClick={handlePriorityButtonClick}
                >
                  {fixPriority(task?.priority || task?.prioridad)}
                </button>
                {openPriorityDropdown === (task?.detalle ? `Registro-${task.id}` : `Tarea-${task.id}`) && (
                  <div
                    ref={priorityDropdownRef}
                    className="fixed z-50 w-2xs bg-white border rounded shadow-lg text-base"
                    style={{ top: dropdownPosition.top, left: dropdownPosition.left }}

                  >
                    {priorityOptions.map((option) => (
                      <button
                        key={`priority-${task.id}-${option.value}`}
                        className={`w-full px-2 py-1 hover:bg-gray-100 text-base ${option.color}`}
                        onClick={() => updateTaskPriority(processId, task.id, option.value, task?.detalle ? "Registro" : "Tarea")}
                      >
                        {option.value}
                      </button>
                    ))}
                  </div>
                )}
              </td>

              <td className="border p-2 relative text-base">
                <button
                  ref={employeeButtonRef}
                  className={`w-full px-2 py-1 rounded text-base ${task?.person || task?.empleado ? 'bg-gray-300' : 'bg-red-500 animate-pulse'}`}
                  onClick={handleEmployeeButtonClick}
                  disabled={!!task?.detalle} // Desactiva el botón si task.detalle existe
                >
                  {task?.person || task?.empleado || 'Asignar empleado'}
                </button>
                {openEmployeesDropdown === (task?.detalle ? `Registro-${task.id}` : `Tarea-${task.id}`) && !task?.detalle && (
                  <div
                    ref={employeeDropdownRef}
                    className="fixed z-50 w-2xs bg-white border rounded shadow-lg text-base max-h-32 overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200"
                    style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
                  >
                    {employees.map((employee) => (
                      <button
                        key={employee.id}
                        className="w-full px-2 py-1 hover:bg-gray-100 text-base"
                        onClick={() => updateTaskEmployee(processId, task.id, employee.id)}
                      >
                        {employee.name}
                      </button>
                    ))}
                  </div>
                )}
              </td>

              <td className="border p-2 text-base text-center">{formatDate(task?.startDate || task?.fecha)}</td>
              <td className="border p-2 text-base text-center">  {task?.endDate || task?.fecha ? formatDate(task?.endDate || task?.fecha) : "Sin asignar"}</td>

              <td className="border p-2 text-base">
                <img
                  src={task?.url}
                  alt="Sin imagen"
                  className="w-8 h-8 rounded-full cursor-pointer"
                  onClick={task?.url ? handleOpenModal : null}
                />
              </td>

              {isModalOpen && (
                <Modal onClose={handleCloseModal}>
                  <img src={task?.url} alt="Imagen ampliada" className="h-[80vh] w-auto object-contain" />
                </Modal>
              )}

              {contextMenu && (
                <div
                  ref={contextMenuRef}
                  className="absolute z-10 bg-white border rounded shadow-lg"
                  style={{ top: contextMenu.mouseY, left: contextMenu.mouseX }}
                >
                  <button className="block w-full text-left px-4 py-2 text-base text-red-600 hover:bg-red-100" onClick={handleDelete}>
                    Borrar {task?.detalle ? "Registro" : "Tarea"}
                  </button>
                </div>
              )}
            </tr>
          )}
        </Draggable>
      ) : (

        <Draggable key={task.id} draggableId={task.issue_id ? `A${task.id}` : task.id} index={index}>
          {/* caso tipo == 2 */}
          {(provided, snapshot) => (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              className={`hover:bg-gray-50 ${snapshot.isDragging ? 'bg-blue-100' : ''}`}
              onContextMenu={handleRightClick}
              onClick={handleCloseMenu}
            >
              <td className="border p-2">
                <span {...provided.dragHandleProps}>
                  <GripVertical className="w-4 h-4 text-gray-400 cursor-move" />
                </span>
              </td>
              {columnsNames.map((column, index) => (
                <React.Fragment key={index}>
                  {column.tipo === "photo-string" ? (
                    <td className="border p-2 text-base">
                      <img
                        src={task?.url}
                        alt="Sin imagen"
                        className="w-8 h-8 rounded-full cursor-pointer"
                        onClick={task?.url ? handleOpenModal : null}
                      />
                    </td>
                  ) :
                    column.tipo === "fecha-string" ? (
                      <td className="border p-2 text-base text-center">{formatDate(task?.startDate || task?.fecha)}</td>
                    ) :
                      (column.tipo === "string-array" || column.tipo === "integer-array") ? (
                        <td className="border p-2 text-base">
                          {cleanDetalleTask(task)[column.titulo] ? (
                            typeof cleanDetalleTask(task)[column.titulo] === 'number' || typeof cleanDetalleTask(task)[column.titulo] === 'string' ? (
                              cleanDetalleTask(task)[column.titulo]
                            ) : (
                              Array.isArray(cleanDetalleTask(task)[column.titulo]) ? (
                                cleanDetalleTask(task)[column.titulo].join(", ")
                              ) : "-"
                            )
                          ) : "-"}
                        </td>
                      ) :

                        (
                          <td className="border p-2 text-base">
                            {task.detalle ? cleanDetalleTask(task)[column.titulo] || "-" : task[column.titulo] || "-"}
                          </td>
                        )}
                </React.Fragment>
              ))}



              {/* estado */}
              <td className="border p-2 relative text-base">
                <button
                  ref={statusButtonRef}
                  className={`w-full px-2 py-1 rounded ${statusOptions.find(option => option.db === task?.status || task?.done)?.color || 'bg-gray-300'}`}
                  onClick={handleStatusButtonClick}
                >
                  {fixState(task?.status) || fixState(task?.done)}
                </button>
                {openStatusDropdown === (task?.detalle ? `Registro-${task.id}` : `Tarea-${task.id}`) && (
                  <div
                    ref={statusDropdownRef}
                    className="fixed z-50 w-2xs bg-white border rounded shadow-lg text-base"
                    style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
                  >
                    {statusOptions.map((option) => (
                      <button
                        key={`status-${task.id}-${option.value}`}
                        className={`w-full px-2 py-1 hover:bg-gray-100 text-base ${option.color}`}
                        onClick={() => updateTaskStatus(processId, task.id, option.value, task?.detalle ? "Registro" : "Tarea")}
                      >
                        {option.value}
                      </button>
                    ))}
                  </div>
                )}
              </td>
              {/* empleados */}

              <td className="border p-2 relative text-base">
                <button
                  ref={employeeButtonRef}
                  className={`w-full px-2 py-1 rounded text-base ${task?.person || task?.empleado ? 'bg-gray-300' : 'bg-red-500 animate-pulse'}`}
                  onClick={handleEmployeeButtonClick}
                  disabled={!!task?.detalle} // Desactiva el botón si task.detalle existe
                >
                  {task?.person || task?.empleado || 'Asignar empleado'}
                </button>
                {openEmployeesDropdown === (task?.detalle ? `Registro-${task.id}` : `Tarea-${task.id}`) && !task?.detalle && (
                  <div
                    ref={employeeDropdownRef}
                    className="fixed z-50 w-2xs bg-white border rounded shadow-lg text-base max-h-32 overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200"
                    style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
                  >
                    {employees.map((employee) => (
                      <button
                        key={employee.id}
                        className="w-full px-2 py-1 hover:bg-gray-100 text-base"
                        onClick={() => updateTaskEmployee(processId, task.id, employee.id)}
                      >
                        {employee.name}
                      </button>
                    ))}
                  </div>
                )}
              </td>

              {/* imagen */}
              {/* <td className="border p-2 text-base">
                <img
                  src={task?.url}
                  alt="Sin imagen"
                  className="w-8 h-8 rounded-full cursor-pointer"
                  onClick={task?.url ? handleOpenModal : null}
                />
              </td> */}

              {isModalOpen && (
                <Modal onClose={handleCloseModal}>
                  <img src={task?.url} alt="Imagen ampliada" className="h-[80vh] w-auto object-contain" />
                </Modal>
              )}

              {contextMenu && (
                <div
                  ref={contextMenuRef}
                  className="absolute z-10 bg-white border rounded shadow-lg"
                  style={{ top: contextMenu.mouseY, left: contextMenu.mouseX }}
                >
                  <button className="block w-full text-left px-4 py-2 text-base text-red-600 hover:bg-red-100" onClick={handleDelete}>
                    Borrar {task?.detalle ? "Registro" : "Tarea"}
                  </button>
                </div>
              )}
            </tr>
          )}
        </Draggable>

      )}
    </>

  )
}

export default Task