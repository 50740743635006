import React, { useEffect, useState, useRef } from "react";
import {
  Slider,
  Button,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useNavigate } from "react-router-dom";
import dotenv from "dotenv";
import 'leaflet/dist/leaflet.css';
import ToggleButton from "../components/ToggleButtonCalc";
import CustomPolygon from '../components/polygon'
import {
  MapContainer,
  TileLayer,
  FeatureGroup
} from 'react-leaflet';
import InputNumber from '../components/inputnumber';
import 'leaflet/dist/leaflet.css';
dotenv.config();

const url = process.env.REACT_APP_BASE_URL;

const cuartelesMap = [
  {
    "id": 1,
    "nombre": "Cuartel 25",
    "superficie": 10.37,
    "zona": 1,
    "coordenadas": [
      [-33.663721, -70.552011],
      [-33.668636, -70.551375],
      [-33.668485, -70.549437],
      [-33.663318, -70.550133],


    ],
  },
  {
    "id": 2,
    "nombre": "Cuartel 26",
    "superficie": 5.93,
    "zona": 2,
    "coordenadas": [
      [-33.663261, -70.549980], // Punto 1 arriba izq
      [-33.662924, -70.547925], // Punto 2 arriva der
      [-33.668474, -70.547186],
      [-33.668527, -70.549221],
    ],
  },

]

const sectoresMap = [
  {
    "id": 1,
    "superficie": 5.36,
    "nombre": "Sector 8",
    "cuartel": 1,
    "coordenadas": [
      [-33.672122, -70.546279],
      [-33.672198, -70.544776],
      [-33.674445, -70.544807],
      [-33.674370, -70.546400]
    ],
  },
  {
    "id": 2,
    "nombre": "Sector 9",
    "superficie": 5.01,
    "cuartel": 1,
    "coordenadas": [
      [-33.672248, -70.543153],
      [-33.672198, -70.544776],
      [-33.674445, -70.544807],
      [-33.674509, -70.543335]
    ],
  },
  {
    "id": 3,
    "nombre": "Sector 8",
    "superficie": 3.12,
    "cuartel": 2,
    "coordenadas": [
      [-33.674471, -70.546354],
      [-33.675986, -70.546461],
      [-33.676137, -70.543502],
      [-33.674673, -70.543320]
    ],
  },
  {
    "id": 4,
    "nombre": "Sector 10",
    "cuartel": 2,
    "superficie": 2.80987,
    "coordenadas": [
      [-33.675986, -70.546461],
      [-33.676137, -70.543502],
      [-33.677893, -70.543608],
      [-33.677842, -70.546506]
    ],
  },

]

export default function CalculationPage() {
  //MAPA
  const mapRef = useRef(null);
  const [getSelectedPolygons, setSelectedPolygons] = useState([]);
  const [getOverMousePolygon, setOverMousePolygon] = useState({});
  const [getUnidades, setUnidades] = useState(cuartelesMap);

  const handlerPolygonHover = (unidad) => {
    setOverMousePolygon(unidad);
  }

  const handlerPolygonMouseOut = (unidad) => {
    setOverMousePolygon({});
  }

  const handleClickChangeUnity = (e) => {
    if (e.target.value === "sectores") {
      setUnidades(sectoresMap)
      setSelectedPolygons([]);
    } else {
      setUnidades(cuartelesMap)
      setSelectedPolygons([]);
    }
    console.log(getSelectedPolygons)
    console.log(e.target.value);
  }
  const handlerPolygonClick = (e) => {
    const index = e.target.options.id;

    if (getSelectedPolygons.includes(index)) {
      setSelectedPolygons([...getSelectedPolygons.filter(val => val !== index)]);
    } else {
      setSelectedPolygons([...getSelectedPolygons, index]);
    }

  }

  const latitude = -33.665889;
  const longitude = -70.549800;



  //-------------------------------------------------------

  const [sliderValue, setSliderValue] = useState(0);
  const navigate = useNavigate()
  const [NSeasonValue, setNSeasonValue] = useState(0);
  const [PSeasonValue, setPSeasonValue] = useState(0);
  const [KSeasonValue, setKSeasonValue] = useState(0);
  const [CaSeasonValue, setCaSeasonValue] = useState(0);
  const [MgSeasonValue, setMgSeasonValue] = useState(0);
  const [Ncoefficient, setNcoefficient] = useState(8);
  const [Pcoefficient, setPcoefficient] = useState(2);
  const [Kcoefficient, setKcoefficient] = useState(12);
  const [Cacoefficient, setCacoefficient] = useState(10);
  const [Mgcoefficient, setMgcoefficient] = useState(2);
  const chartRef = useRef(null);

  if (!localStorage.getItem("jwt")) {
    navigate("/login", { replace: true })
  }
  const token = localStorage.getItem("jwt");
  const headers = {
    "auth": token
  }

  const valuetext = (e, value) => {
    setSliderValue(value);
    setNSeasonValue(value * Ncoefficient);
    setPSeasonValue(value * Pcoefficient);
    setKSeasonValue(value * Kcoefficient);
    setCaSeasonValue(value * Cacoefficient);
    setMgSeasonValue(value * Mgcoefficient);
  };

  const mark = [
    {
      value: 0,
      label: '0 TON / Ha'
    },
    {
      value: 50,
      label: '50 TON / Ha'
    }
  ];


  const setUnitObjective = () => {
    let TipoUnidad = "";
    let unitObjective = {};
    let surface = getUnidades.filter(val => getSelectedPolygons.includes(val.id)).map(val => val.superficie).reduce((a, b) => a + b, 0)
    console.log(surface)
    if (getUnidades.length === 2) {
      TipoUnidad = "cuartel";
      unitObjective = {
        "cuartel_id": getSelectedPolygons,
        "limite_n": parseInt(Math.ceil(NSeasonValue * surface)),
        "limite_p": parseInt(Math.ceil(PSeasonValue * surface)),
        "limite_k": parseInt(Math.ceil(KSeasonValue * surface)),
        "limite_ca": parseInt(Math.ceil(CaSeasonValue * surface)),
        "limite_mg": parseInt(Math.ceil(MgSeasonValue * surface)),
        "objetivo_produccion": sliderValue,
        "coeficiente_n": Ncoefficient,
        "coeficiente_p": Pcoefficient,
        "coeficiente_k": Kcoefficient,
        "coeficiente_ca": Cacoefficient,
        "coeficiente_mg": Mgcoefficient,
      }

    }
    else {
      TipoUnidad = "sector";
      unitObjective = {
        "sector_id": getSelectedPolygons,
        "limite_n": NSeasonValue,
        "limite_p": PSeasonValue,
        "limite_k": KSeasonValue,
        "limite_ca": CaSeasonValue,
        "limite_mg": MgSeasonValue,
        "objetivo_produccion": sliderValue,
        "coeficiente_n": Ncoefficient,
        "coeficiente_p": Pcoefficient,
        "coeficiente_k": Kcoefficient,
        "coeficiente_ca": Cacoefficient,
        "coeficiente_mg": Mgcoefficient,
      }
    }

    fetch(`${url}/calculos/${TipoUnidad}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers
      },
      body: JSON.stringify(unitObjective)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
      })
      .catch(error => console.log(error))

    alert(`Objetivo de producción guardado para las unidades ${getUnidades.filter(val => getSelectedPolygons.includes(val.id)).map(val => val.nombre).join(" - ")}. redireccionando...`);
    setTimeout(() => {
      document.location.href = "/dashboard/Fundos";
    }, 1000);
  }

  useEffect(() => {
    Chart.register(ChartDataLabels);
    const necesidadData = [NSeasonValue, PSeasonValue, KSeasonValue, CaSeasonValue, MgSeasonValue];
    const chart = new Chart(chartRef.current, {
      type: "bar",
      data: {
        labels: ["N", "P", "K", "Ca", "Mg"],
        datasets: [
          {
            label: 'Unidades / Ha',
            data: necesidadData,
            borderColor: '#d9d9d9',
            barThickness: 30,
            borderRadius: 25,
          },
        ]
      },
      options: {
        plugins: {
          datalabels: {
            // Cambia la posición de las etiquetas si es necesario
            // Cambia la alineación de las etiquetas si es necesario
            font: {
              size: 16,
              weight: 'bold', // Ajusta el tamaño de la fuente aquí
              // Ajusta el color de la fuente aquí
            }
          }
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
            reverse: true,
            grid: { display: false }
          },
          x: {
            grid: { display: false },
          }
        },
        responsive: true,
      },
    });

    return () => {
      chart.destroy();
    };
  }, [NSeasonValue, PSeasonValue, KSeasonValue, CaSeasonValue, MgSeasonValue, Ncoefficient, Pcoefficient, Kcoefficient, Cacoefficient, Mgcoefficient]);


  return (
    <div>
      <Card sx={{ px: 2.5, py: 2.5 }} style={{ height: "50vh" }}>
        <Button
          variant={'contained'}
          sx={{ color: 'white' }}
          style={{
            margin: '0 5px',
            backgroundColor: 'rgb(81, 160, 154)',
          }}
          onClick={() => {
            setSelectedPolygons([]);
            setUnidades(sectoresMap);
          }}
        >
          Sectores
        </Button>
        <Button
          variant={'contained'}
          sx={{ color: 'white' }}
          style={{
            margin: '0 5px',
            backgroundColor: 'rgb(81, 160, 154)',
          }}
          onClick={() => {
            setSelectedPolygons([]);
            setUnidades(cuartelesMap);
          }}
        >
          Cuarteles
        </Button>
        <Button
          variant={'contained'}
          sx={{ color: 'white' }}
          style={{
            margin: '0 5px',
            backgroundColor: 'rgb(81, 160, 154)',
          }}
          onClick={() => {
            const map = mapRef.current;
            if (map) {
              map.flyTo([latitude, longitude], 14);
            }
          }}
        >
          Centrar
        </Button>
        <Grid container style={{ height: '100%' }} spacing={1}>
          <Grid item xs={5}>
            <MapContainer
              center={[latitude, longitude]}
              zoom={13}
              style={{ height: "90%", width: "100%", marginTop: "10px" }}
              ref={mapRef}
            >
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                attribution="MIIDO"

              />

              {getUnidades.map((unidad, index) => (
                <FeatureGroup>
                  <CustomPolygon
                    index={unidad.id}
                    unidad={unidad}
                    onClick={(e) => {
                      handlerPolygonClick(e)
                    }}
                    setMouseOver={handlerPolygonHover}
                    setMouseOut={handlerPolygonMouseOut}
                    selected={getSelectedPolygons.includes(unidad.id)}
                  />

                </FeatureGroup>
              ))}

            </MapContainer>

          </Grid>

          {Object.keys(getSelectedPolygons).length > 0 ?
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h6" color="text.secondary">
                    Localización :

                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    {getUnidades.filter(val => getSelectedPolygons.includes(val.id)).map(val => val.coordenadas[0]).join("\n")}

                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h6" color="text.secondary">
                    Unidad:

                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    {getUnidades.filter(val => getSelectedPolygons.includes(val.id)).map(val => val.nombre).join(" - ")}

                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ marginTop: 3 }}>
                <Grid item xs={6} >
                  <Typography variant="h6" color="text.secondary">
                    Superficie :

                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    {getUnidades.filter(val => getSelectedPolygons.includes(val.id)).map(val => val.superficie).join(" - ")}

                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h6" color="text.secondary">
                    Encargada :

                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    Luz Quezada

                  </Typography>
                </Grid>

              </Grid>





            </Grid>

            :
            <Grid item xs={6}>
              <Grid container spacing={2} >
                <Grid item xs={6}>
                  <Typography variant="h6" color="text.secondary">
                    Localización :

                  </Typography>

                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h6" color="text.secondary">
                    Unidad:

                  </Typography>

                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ marginTop: 3 }}>
                <Grid item xs={6} sx={{ marginTop: 3 }}>
                  <Typography variant="h6" color="text.secondary">
                    Superficie :

                  </Typography>

                </Grid>

                <Grid item xs={6} sx={{ marginTop: 3 }}>
                  <Typography variant="h6" color="text.secondary">
                    Encargada :

                  </Typography>

                </Grid>
              </Grid>
            </Grid>


          }
        </Grid>


      </Card>


      <Card sx={{ px: 2.5, py: 2.5, marginTop: 1 }} style={{ height: "50vh" }}>
        <Grid container spacing={2} >
          <Grid item xs={11}>
            <ToggleButton selectedButton={1}>
            </ToggleButton>
          </Grid>
          <Grid item xs={1}>
            <Button
              style={{ backgroundColor: 'rgb(81, 160, 154)', color: 'white' }}
              variant="contained"
              onClick={setUnitObjective}>
              Guardar
            </Button>
          </Grid>


        </Grid>

        <Typography variant="h4" color="text.secondary" sx={{ marginTop: 2 }}>
          Ajuste su objetivo de producción
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <div style={{ width: 300, margin: 30 }}>
              <Slider
                sx={{ color: "#C2DB64" }}
                defaultValue={0}
                max={50}
                step={1}
                marks={mark}
                valueLabelDisplay="auto"
                onChange={valuetext}
              />
            </div>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <InputNumber label="Ncoef" onChange={(quantity) => { setNcoefficient(quantity) }}></InputNumber>
              </Grid>
              <Grid item xs={2}>
                <InputNumber label="Pcoef" onChange={(quantity) => { setPcoefficient(quantity) }}></InputNumber>
              </Grid>
              <Grid item xs={2}>
                <InputNumber label="Kcoef" onChange={(quantity) => { setKcoefficient(quantity) }}></InputNumber>
              </Grid>
              <Grid item xs={2}>
                <InputNumber label="Mgcoef" onChange={(quantity) => { setMgcoefficient(quantity) }}></InputNumber>
              </Grid>
              <Grid item xs={2}>
                <InputNumber label="Cacoef" onChange={(quantity) => { setCacoefficient(quantity) }}></InputNumber>
              </Grid>
            </Grid>

            <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>
              Valor seleccionado: {sliderValue} TON / Ha
            </Typography>

            <div>

            </div>
          </Grid>
          <Grid item xs={5}>
            <div style={{ height: '70%' }}>
              <canvas ref={chartRef}></canvas>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>

  );
}
