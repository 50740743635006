import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// @mui
import { Typography, Paper, Box } from "@mui/material";
import { LoginForm } from "../sections/auth/login";
import { LoginButton } from "../sections/auth/login";
import { set } from "lodash";


export default function LoginLCP() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (localStorage.jwt) {
      localStorage.removeItem("jwt");
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [navigate]);

  if (isLoading) {
    return null;
  }

  return (
    <div style={{ background: "#2f3d44", width: "100%", height: "130vh" }}>
      <Helmet>
        <title> Log In </title>
      </Helmet>

      <Box pt={4}>
        <Paper sx={{ width: "420px", m: "auto", p: 4, height: "582px" }}>
          <Box mb={2}>
            { }
            <img
              style={{ margin: "auto", width: "48px" }}
              src="/assets/icons/logoOnLogin.svg"
              alt="SVG as an image"
            />
          </Box>
          <Typography
            variant="h5"
            color="lightgray"
            sx={{ textAlign: "center" }}
          >
            Dashboard Miido
          </Typography>
          <br />
          <Typography
            variant="h4"
            gutterBottom
            textAlign={"center"}
            sx={{ fontSize: "24px" }}
          >
            Bienvenido a tu Dashboard
          </Typography>
          {/* <LoginButton /> */}
          <LoginForm from={"LCP"}/>
        </Paper>
      </Box>
    </div>
  );
}
