'use client'

import React, { useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { GripVertical, Edit2, Check, Trash2, ChevronDown, ChevronUp, Download } from 'lucide-react'
import SemillaTask from './SemillaTask'
import * as XLSX from 'xlsx'
import MiidoTask from './MiidoTask'
import BandejaTask from './BandejasTask'


const statusOptions = [
  { value: 'Pendiente', db: 0, color: 'bg-yellow-500' },
  { value: 'En progreso', db: 1, color: 'bg-orange-500' },
  { value: 'Hecho', db: 2, color: 'bg-green-500' },
]

const priorityOptions = [
  { value: 'Alto', db: 3, color: 'bg-red-500' },
  { value: 'Medio', db: 2, color: 'bg-yellow-500' },
  { value: 'Bajo', db: 1, color: 'bg-blue-500' },
]
const formatDate = (dateString) => {
  if (!dateString) return ''; // Manejar casos donde dateString es null o undefined
  return dateString.split('T')[0];
}

const handleDownload = (process) => {
  try{
    console.log("process type", process.tipo);
    // Define el encabezado
    const processHeader = {
      1: ["Título", "Numerico", "Estado", "Persona", "Fecha Inicio", "Fecha Término", "Prioridad", "Imagen", "Ubicación"],
      2: ["Título", "PHK", "Peso", "Estado", "Persona", "Fecha", "Imagen"],
      3: ["Título", "cantidad","Bandejas", "Remanente", "Estado", "Persona", "Fecha", "Imagen"],
    }
    const header = processHeader[process.tipo];
    
    const processRows = {
      1: process.tasks.map(task => [
        task.task || task.detalle,
        task.numerico || "-", // Asumiendo que hay una propiedad number
        statusOptions.find(status => status.db === task.status)?.value,
        task.person || task.empleado,
        formatDate(task.startDate || task.fecha),
        formatDate(task.endDate),
        priorityOptions.find(priority => priority.db === task.priority)?.value,
        task.url || "Sin imagen", // Asumiendo que hay una propiedad image
        task.ubicacion || "Sin ubicación", // Asumiendo que hay una propiedad location
      ]),
      2: process.tasks.map(task => {
        const numericoObj = task.numerico ? JSON.parse(task.numerico) : {};
        return [
          task.task || task.detalle,
          numericoObj.cantidad || "-",
          numericoObj.peso || "-",
          statusOptions.find(status => status.db === task.status)?.value || "-",
          task.person || task.empleado,
          formatDate(task.startDate || task.fecha),
          task.url || "Sin imagen",
        ];
      }),
      3: process.tasks.map(task => {
        const numericoObj = task.numerico ? JSON.parse(task.numerico) : {};
        return [
          task.task || task.detalle,
          numericoObj.cantidad || "-",
          numericoObj.bandejas || "-",
          numericoObj.restantes || "-",
          statusOptions.find(status => status.db === task.status)?.value || "-",
          task.person || task.empleado,
          formatDate(task.startDate || task.fecha),
          task.url || "Sin imagen",
        ];
      }),
    };
    
    // Asegúrate de que `type` sea un número
    const type = Number(process.tipo);
    
    // Accede solo a la clave correspondiente al valor de `type`
    const rows = processRows[type] || [];

    console.log("header", header);
    console.log("rows", rows);


    // Combina el encabezado y las filas
    const data = [header].concat(rows);


    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // Crea un libro de trabajo y agrega la hoja de trabajo
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, process.title);

    // Genera un archivo Excel
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Crea un Blob a partir del buffer
    const file = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Crea un enlace para descargar el archivo
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = `${process.title}.xlsx`;
    document.body.appendChild(element); // Requerido para que funcione en Firefox
    element.click();
    document.body.removeChild(element); // Limpia el DOM
  }
  catch (error) {
    console.error("Error al descargar el archivo Excel", error);
  }
};


const Process = ({
  process,
  index,
  type,
  toggleStatusDropdown,
  togglePriorityDropdown,
  toggleEmployeesDropdown,
  updateTaskStatus,
  updateTaskPriority,
  updateTaskEmployee,
  openStatusDropdown,
  openPriorityDropdown,
  openEmployeesDropdown,
  toggleEditMode,
  updateProcessTitle,
  finishEditingProcessTitle,
  handleKeyDown,
  deleteProcess,
  addNewTask,
  updateNewTaskContent,
  employees,
  addNewProcess,
  getBoards,
  updateTaskTitle,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const configuration = process["configuracion"] ? JSON.parse(process["configuracion"]) : {}
  const order = configuration["orden"] || []

  const orderMap = new Map(order.map((id, index) => {
    const key = typeof id === 'number' ? id : id;
    return [key, index];
  }));

  const orderedTasks = process.tasks.sort((a, b) => {
    const idA = a.detalle ? `A${a.id}` : a.id;
    const idB = b.detalle ? `A${b.id}` : b.id;
    const orderA = orderMap.get(idA) !== undefined ? orderMap.get(idA) : Infinity;
    const orderB = orderMap.get(idB) !== undefined ? orderMap.get(idB) : Infinity;
    return orderA - orderB;
  });

  return (
    <Draggable key={process.id} draggableId={process.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`bg-white rounded-lg shadow-md p-4 ${snapshot.isDragging ? 'border-2 border-blue-500' : ''}`}
        >
          <div className="flex justify-between items-center mb-2">
            <div {...provided.dragHandleProps} className="flex items-center cursor-move">
              <GripVertical className="w-5 h-5 mr-2 text-gray-400" />

              {process.isEditing ? (
                <input
                  type="text"
                  value={process.title}
                  onChange={(e) => updateProcessTitle(process.id, e.target.value)}
                  onBlur={() => finishEditingProcessTitle(process.id)}
                  onKeyDown={(e) => handleKeyDown(e, process.id)}
                  className="font-semibold border rounded px-2 py-1"
                  autoFocus
                />
              ) : (
                <h2 className="text-lg font-semibold">{process.title}</h2>


              )}
              <button
                className="text-gray-600 hover:text-gray-900 ml-2"
                onClick={() => setIsCollapsed(!isCollapsed)}
                aria-expanded={!isCollapsed}
                aria-label={isCollapsed ? "Expandir proceso" : "Colapsar proceso"}
              >
                {isCollapsed ? <ChevronDown className="w-5 h-5" /> : <ChevronUp className="w-5 h-5" />}
              </button>
            </div>
            <div className="flex items-center space-x-2">
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => toggleEditMode(process.id)}
                aria-label={process.isEditing ? "Guardar cambios" : "Editar proceso"}
              >
                {process.isEditing ? <Check className="w-4 h-4" /> : <Edit2 className="w-4 h-4" />}
              </button>
              <button
                className="text-red-600 hover:text-red-800"
                onClick={() => deleteProcess(process.id)}
                aria-label="Eliminar proceso"
              >
                <Trash2 className="w-4 h-4" />
              </button>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => handleDownload(process)}
                aria-label="Descargar proceso"
              >
                <Download className="w-4 h-4" />
              </button>

            </div>
          </div>
          {/* Tipos de tableros, 2 seleccion, 3 Siembra, 1 default (Miido) */}
          {!isCollapsed && (
            <>
              {type === 2 && ( //semillas
                <Droppable droppableId={process.id} type="task">
                  {(provided) => (
                    <div className="overflow-x-auto max-h-[250px]" {...provided.droppableProps} ref={provided.innerRef}>
                      <table className="w-full border-collapse">
                        <thead>
                          <tr className="bg-gray-100">
                            <th className="border p-2 w-4xs text-base"></th>
                            <th className="border p-2 w-1xs text-base sticky bg-gray-100 z-10" style={{ left: '-1px' }}>Título</th>
                            <th className="border p-2 w-3xs text-base">Código</th>
                            <th className="border p-2 w-3xs text-base">PHK</th>
                            <th className="border p-2 w-3xs text-base">Peso</th>
                            <th className="border p-2 w-3xs text-base">Estado</th>
                            <th className="border p-2 w-3xs text-base">Persona</th>
                            <th className="border p-2 w-3xs text-base">Fecha</th>
                            <th className="border p-2 w-3xs text-base">Imagen</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderedTasks.length > 0 ? orderedTasks.map((task, index) => (
                            <SemillaTask
                            key={task.issue_id ? `A${task.id}` : task.id}
                            type={type}
                            task={task}
                            index={index}
                            processId={process.id}
                            statusOptions={statusOptions}
                            priorityOptions={priorityOptions}
                            toggleStatusDropdown={toggleStatusDropdown}
                            togglePriorityDropdown={togglePriorityDropdown}
                            toggleEmployeesDropdown={toggleEmployeesDropdown}
                            updateTaskStatus={updateTaskStatus}
                            updateTaskPriority={updateTaskPriority}
                            updateTaskEmployee={updateTaskEmployee}
                            openStatusDropdown={openStatusDropdown}
                            openPriorityDropdown={openPriorityDropdown}
                            openEmployeesDropdown={openEmployeesDropdown}
                            employees={employees}
                            getBoards={getBoards}
                            updateTaskTitle={updateTaskTitle}
                          />
                          )) : (
                            <tr>
                              <td colSpan="8" className="border p-2 text-center">No hay tareas</td>
                            </tr>
                          )}
                          {provided.placeholder}
                        </tbody>
                      </table>
                    </div>
                  )}
                </Droppable>
              )}
              {type === 1 && (
                <Droppable droppableId={process.id} type="task">
                {(provided) => (
                  <div className="overflow-x-auto max-h-[250px]" {...provided.droppableProps} ref={provided.innerRef}>
                    <table className="w-full border-collapse">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="border p-2 w-4xs text-base"></th>
                          <th className="border p-2 w-1xs text-base sticky bg-gray-100 z-10" style={{ left: '-1px'  }}>Título</th>
                          <th className="border p-2 w-3xs text-base">Númerico</th>
                          <th className="border p-2 w-3xs text-base">Tipo</th>
                          <th className="border p-2 w-3xs text-base">Ubicación</th>
                          <th className="border p-2 w-3xs text-base">Estado</th>
                          <th className="border p-2 w-3xs text-base">Prioridad</th>
                          <th className="border p-2 w-3xs text-base">Persona</th>
                          <th className="border p-2 w-3xs text-base">Fecha Inicio</th>
                          <th className="border p-2 w-3xs text-base">Fecha Término</th>
                          <th className="border p-2 w-3xs text-base">Imagen</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {orderedTasks.length > 0 ? orderedTasks.map((task, index) => (
                          <MiidoTask
                            key={task.issue_id ? `A${task.id}` : task.id}
                            task={task}
                            index={index}
                            processType={type}
                            processId={process.id}
                            statusOptions={statusOptions}
                            priorityOptions={priorityOptions}
                            toggleStatusDropdown={toggleStatusDropdown}
                            togglePriorityDropdown={togglePriorityDropdown}
                            toggleEmployeesDropdown={toggleEmployeesDropdown}
                            updateTaskStatus={updateTaskStatus}
                            updateTaskPriority={updateTaskPriority}
                            updateTaskEmployee={updateTaskEmployee}
                            openStatusDropdown={openStatusDropdown}
                            openPriorityDropdown={openPriorityDropdown}
                            openEmployeesDropdown={openEmployeesDropdown}
                            employees={employees}
                            getBoards={getBoards}
                            updateTaskTitle={updateTaskTitle}
                          />
                        )) : (
                          <tr>
                            <td colSpan={10} className="text-center p-4">Sin tareas</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
                )}
                {type === 3 && ( //Seleccion bandejas
                <Droppable droppableId={process.id} type="task">
                {(provided) => (
                  <div className="overflow-x-auto max-h-[250px]" {...provided.droppableProps} ref={provided.innerRef}>
                    <table className="w-full border-collapse">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="border p-2 w-4xs text-base"></th>
                          <th className="border p-2 w-1xs text-base sticky bg-gray-100 z-10" style={{ left: '-1px'  }}>Título</th>
                          <th className="border p-2 w-3xs text-base">Código</th>
                          <th className="border p-2 w-3xs text-base">Cantidad</th>
                          <th className="border p-2 w-3xs text-base">Bandejas</th>
                          <th className="border p-2 w-3xs text-base">Remanente</th>
                          <th className="border p-2 w-3xs text-base">Estado</th>
                          <th className="border p-2 w-3xs text-base">Persona</th>
                          <th className="border p-2 w-3xs text-base">Fecha</th>
                          <th className="border p-2 w-3xs text-base">Imagen</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {orderedTasks.length > 0 ? orderedTasks.map((task, index) => (
                          <BandejaTask
                            key={task.issue_id ? `A${task.id}` : task.id}
                            task={task}
                            type={type}
                            index={index}
                            processId={process.id}
                            statusOptions={statusOptions}
                            priorityOptions={priorityOptions}
                            toggleStatusDropdown={toggleStatusDropdown}
                            togglePriorityDropdown={togglePriorityDropdown}
                            toggleEmployeesDropdown={toggleEmployeesDropdown}
                            updateTaskStatus={updateTaskStatus}
                            updateTaskPriority={updateTaskPriority}
                            updateTaskEmployee={updateTaskEmployee}
                            openStatusDropdown={openStatusDropdown}
                            openPriorityDropdown={openPriorityDropdown}
                            openEmployeesDropdown={openEmployeesDropdown}
                            employees={employees}
                            getBoards={getBoards}
                            updateTaskTitle={updateTaskTitle}
                          />
                        )) : (
                          <tr>
                            <td colSpan={10} className="text-center p-4">Sin tareas</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
                )}
              <div className="mt-2 flex space-x-2">
                <input
                  type="text"
                  placeholder="+ Añadir Tarea"
                  value={process.newTaskContent}
                  onChange={(e) => updateNewTaskContent(process.id, e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && addNewTask(process.id)}
                  className="flex-grow border rounded px-2 py-1"
                />
                <button
                  onClick={() => addNewTask(process.id)}
                  className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
                >
                  Añadir
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </Draggable>
  )
}

export default Process