import { is } from "date-fns/locale";

export function Table({ children }) {
  return <table className="w-full border-collapse">{children}</table>;
}

export function TableHeader({ children }) {
  return <thead className="sticky top-0 bg-white z-10 ">{children}</thead>;
}

export function TableBody({ children }) {
  return <tbody className="bg-white">{children}</tbody>;
}

export function TableRow({ children, isRed, isGreen, isYellow, onClick }) {
  return (
    <tr 
    onClick={onClick}
    className={`
      border
      ${isRed ? 'bg-red-100 border-l-4 border-l-red-500' : isGreen ? 'bg-green-100 border-l-4 border-l-green-500' : isYellow ? 'bg-yellow-100 border-l-4 border-l-yellow-500' : ''}
      transition-colors duration-200 ease-in-out
      hover:bg-gray-50
    `}>
      {children}
    </tr>
  );
}

export function TableCell({ children}) {
  return <td className="border p-4 text-sm">{children}</td>;
}

export function TableHead({ children }) {
  return <th className="border p-4 text-sm font-bold text-left">{children}</th>;
}