import React, { useState, useEffect, useRef } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './Table';
import { format } from 'date-fns';
import { Button } from './Button';
import Input from './Input';


function fixDate(dateString) {
  const date = new Date(dateString);

  // Leer el offset de localStorage y convertirlo a número
  const offsetHours = Number(localStorage.getItem('offset')) * -1;

  // Convertir el offset de horas a milisegundos y aplicarlo
  const offsetMilliseconds = offsetHours * 60 * 60 * 1000;
  date.setTime(date.getTime() + offsetMilliseconds);

  const pad = (num) => (num < 10 ? '0' + num : num);

  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1); // Los meses en JavaScript son 0-11
  const year = date.getFullYear();
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

const extractDate = (dateString) => {
  const date = new Date(dateString);
  return format(date, 'dd/MM/yy');
};

const Select = ({ value, onChange, options, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={selectRef} className="relative">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full h-12 px-4 text-left bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-base"
      >
        <span className="block truncate">
          {value ? options.find(opt => opt === value) || `Tod${value === 'operacion' ? 'as' : 'os'}` : placeholder}
        </span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </span>
      </button>
      {isOpen && (
        <div className="absolute z-20 w-full mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
          {options.map((option) => (
            <div
              key={option}
              className={`${option === value ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                } cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100`}
              onClick={() => {
                onChange(option);
                setIsOpen(false);
              }}
            >
              <span className={`block truncate ${option === value ? 'font-medium' : 'font-normal'}`}>
                {option || `Tod${option === 'operacion' ? 'as' : 'os'}`}
              </span>
              {option === value && (
                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default function MessageHistory({ messages }) {
  const [filtroTelefono, setFiltroTelefono] = useState('');
  const [filtroTipoMensaje, setFiltroTipoMensaje] = useState('');
  const [filtroOperacion, setFiltroOperacion] = useState('');
  const [filtroFecha, setFiltroFecha] = useState('');
  const [filtroRespuesta, setFiltroRespuesta] = useState('');
  const [filtroNombre, setFiltroNombre] = useState('');
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null);

  const handleFiltroTelefonoChange = (value) => {
    setFiltroTelefono(value);
  };

  const handleFiltroNombreChange = (value) => {
    setFiltroNombre(value);
  };

  const handleFiltroTipoMensajeChange = (value) => {
    setFiltroTipoMensaje(value);
  };

  const handleFiltroOperacionChange = (value) => {
    setFiltroOperacion(value);
  };

  const handleFiltroFechaChange = (value) => {
    setFiltroFecha(value);
  };

  const handleFiltroRespuestaChange = (value) => {
    setFiltroRespuesta(value);
  };

  const handleResetFilters = () => {
    setFiltroNombre('');
    setFiltroTelefono('');
    setFiltroTipoMensaje('');
    setFiltroOperacion('');
    setFiltroFecha('');
    setFiltroRespuesta('');
  };

  const telefonosUnicos = [...new Set(messages.map(message => message.telefono))];
  const tiposMensajeUnicos = [...new Set(messages.map(message => message.tipo_mensaje))];
  const operacionesUnicas = [...new Set(messages.map(message => message.operacion))];
  const fechasUnicas = [...new Set(messages.map(message => extractDate(message.fecha)))];
  const respuestasUnicas = ["enviado", "Si asisto", "No asisto", "Me faltó entregar", "Si puedo ayudar!"];
  const mensajesFiltrados = messages.filter((message) => {
    const coincideNombre = message.nombre.toLowerCase().includes(filtroNombre.toLowerCase());
    console.log("coincideNombre", coincideNombre);
    return (
      coincideNombre &&
      (filtroTelefono === '' || message.telefono === filtroTelefono) &&
      (filtroTipoMensaje === '' || message.tipo_mensaje === filtroTipoMensaje) &&
      (filtroOperacion === '' || message.operacion === filtroOperacion) &&
      (filtroFecha === '' || extractDate(message.fecha) === filtroFecha) &&
      (filtroRespuesta === '' || message.respuesta_mensaje === filtroRespuesta)
    );
  });

  // Ordenar los mensajes por fecha en orden descendente y tomar los primeros 10
  const mensajesOrdenados = mensajesFiltrados.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));

  // Agrupar los mensajes por usuario
  const mensajesPorUsuario = mensajesOrdenados.reduce((acc, message) => {
    const key = `${message.nombre}-${message.telefono}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(message);
    return acc;
  }, {});

  // Obtener los últimos 10 mensajes por usuario
  const ultimosMensajesPorUsuario = Object.entries(mensajesPorUsuario).reduce((acc, [key, mensajes]) => {
    // Seleccionar los últimos 10 mensajes
    acc[key] = mensajes.slice(0, 10);
    return acc;
  }, {});

  const handleUsuarioClick = (usuario) => {
    setUsuarioSeleccionado(usuarioSeleccionado === usuario ? null : usuario);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Historial de Mensajes</h1>

      <div className="mb-6 flex justify-between items-center">
        <div className="w-64">
          <Select
            value={filtroTelefono}
            onChange={handleFiltroTelefonoChange}
            options={telefonosUnicos}
            placeholder="Filtrar por teléfono"
          />
        </div>
        <div className="w-64">
          <Select
            value={filtroTipoMensaje}
            onChange={handleFiltroTipoMensajeChange}
            options={tiposMensajeUnicos}
            placeholder="Filtrar por tipo de mensaje"
          />
        </div>
        <div className="w-64">
          <Select
            value={filtroRespuesta}
            onChange={handleFiltroRespuestaChange}
            options={respuestasUnicas}
            placeholder="Filtrar por respuesta"
          />
        </div>
        <div className="w-64">
          <Select
            value={filtroFecha}
            onChange={handleFiltroFechaChange}
            options={fechasUnicas}
            placeholder="Filtrar por fecha"
          />
        </div>
        <div className="w-64">
          <Select
            value={filtroOperacion}
            onChange={handleFiltroOperacionChange}
            options={operacionesUnicas}
            placeholder="Filtrar por operacion"
          />
        </div>
        <Button className="bg-black hover:bg-gray-800" onClick={handleResetFilters}>Limpiar Filtros</Button>
      </div>
      <div className="mb-6 space-y-4">
        {/* Opción 1: Lupa más a la izquierda */}
        <div className="relative">
          <input
            type="text"
            value={filtroNombre}
            onChange={(e) => handleFiltroNombreChange(e.target.value)}
            placeholder="Buscar por nombre..."
            className="w-full h-12 pl-8 pr-4 text-base bg-transparent border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 transition-colors duration-300"
          />
          <svg
            className="absolute left-0 top-3 w-6 h-6 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
      </div>

      <div className="max-h-[700px] overflow-y-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Nombre</TableHead>
              <TableHead>Teléfono</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Vehículo</TableHead>
              <TableHead>Patente</TableHead>
              <TableHead>Región</TableHead>
              <TableHead>Último Mensaje</TableHead>
              <TableHead>Estado Mensajes</TableHead> {/* Nueva columna */}
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.entries(mensajesPorUsuario).map(([usuario, mensajes]) => {
              const ultimoMensaje = mensajes[0];

              // Crear los colores basados en las condiciones dadas
              const coloresMensajes = mensajes.map((message) => {
                if (message.respuesta_mensaje === "No asisto" || message.respuesta_mensaje === "Me faltó entregar") {
                  return 'bg-red-500';
                } else if (message.respuesta_mensaje === "Si asisto" || Number.isInteger(parseInt(message.respuesta_mensaje))) {
                  return 'bg-green-500';
                } else if (message.respuesta_mensaje === "enviado") {
                  return 'bg-yellow-500';
                }
                return 'bg-gray-500'; // Color por defecto si no coincide con ninguna condición
              });

              return (
                <React.Fragment key={usuario}>
                  <TableRow
                    onClick={() => handleUsuarioClick(usuario)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <TableCell>{ultimoMensaje.nombre}</TableCell>
                    <TableCell>{ultimoMensaje.telefono}</TableCell>
                    <TableCell>{ultimoMensaje.email}</TableCell>
                    <TableCell>{ultimoMensaje.vehiculo}</TableCell>
                    <TableCell>{ultimoMensaje.patente}</TableCell>
                    <TableCell>{ultimoMensaje.region}</TableCell>
                    <TableCell>{fixDate(ultimoMensaje.fecha)}</TableCell>
                    <TableCell>
                      <div className="flex space-x-1">
                        {coloresMensajes.slice(0, 10).map((color, index) => (
                          <span key={index} className={`w-4 h-4 rounded-full ${color}`}></span>
                        ))}
                      </div>
                    </TableCell>
                  </TableRow>
                  {usuarioSeleccionado === usuario && (
                    <div onClick={() => setUsuarioSeleccionado(null)} className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto">
                      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-2xl">
                        <div className="flex justify-between items-center mb-4">
                          <h2 className="text-xl font-bold">Ficha de {ultimoMensaje.nombre}</h2>
                          <button
                            onClick={() => setUsuarioSeleccionado(null)}
                            className="text-gray-500 hover:text-gray-700"
                          >
                            <svg
                              className="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHead>Operación a participar</TableHead>
                              <TableHead>Respuesta</TableHead>
                              <TableHead>Tipo de Mensaje</TableHead>
                              <TableHead>Fecha</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {mensajes.map((message) => (
                              <TableRow
                                key={message.id}
                                isRed={message.respuesta_mensaje === "No asisto" || message.respuesta_mensaje === "Me faltó entregar"}
                                isGreen={message.respuesta_mensaje === "Si asisto" || message.respuesta_mensaje === "Si asisto" || Number.isInteger(parseInt(message.respuesta_mensaje))}
                                isYellow={message.respuesta_mensaje === "enviado"}
                              >
                                <TableCell>
                                  {message.operacion_a_participar !== 'undefined'
                                    ? message.operacion_a_participar
                                    : message.operacion}
                                </TableCell>
                                <TableCell>{message.respuesta_mensaje}</TableCell>
                                <TableCell>{message.tipo_mensaje}</TableCell>
                                <TableCell>{fixDate(message.fecha)}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}