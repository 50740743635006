import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import dotenv from 'dotenv';
import CustomPolygon from '../components/polygon'
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  MapContainer,
  TileLayer,
  FeatureGroup
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import {
  Button,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  Box,
  Grid,
  Container,
  Stack,
  CircularProgress
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import ToggleButton from '../components/ToggleButton';
import InputNumber from '../components/inputnumber';
import PropTypes from 'prop-types';
import Chart from "chart.js/auto";
import * as XLSX from 'xlsx';
import Alert from '@mui/material/Alert';
dotenv.config();
const url = process.env.REACT_APP_BASE_URL;


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function getRowColor(product) {
  if (product.quantity > 0) {
    return 'rgba(81, 160, 154, 0.15)'
    //return 'rgba(217, 217, 217, 0.8)'
  }
  else {
    return '#FFFFFF'
  }
}
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${props.type}: ${props.display}`}
        </Typography>
      </Box>
    </Box>
  );
}



function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  /* TODO: Cambiar la tabla de compra a un componente y cambiar estados */
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ width: '10px', padding: '8px' }} padding="none">
          Cantidad
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const headCells = [
  {
    id: 'Proveedor',
    numeric: false,
    disablePadding: false,
    label: 'Proveedor ',
  },
  {
    id: 'CapacidadEnvase',
    numeric: false,
    disablePadding: false,
    label: 'CapacidadEnvase ',
  },
  {
    id: 'Nombre',
    numeric: false,
    disablePadding: false,
    label: 'Nombre',
  },
  {
    id: 'precio',
    numeric: false,
    label: 'Precio',
  },
  {
    id: 'N',
    numeric: false,
    disablePadding: false,
    label: 'N',
  },
  {
    id: 'P',
    numeric: false,
    disablePadding: false,
    label: 'P',
  },
  {
    id: 'K',
    numeric: false,
    disablePadding: false,
    label: 'K',
  },
  {
    id: 'Ca',
    numeric: false,
    disablePadding: false,
    label: 'Ca',
  },
  {
    id: 'Mg',
    numeric: false,
    disablePadding: false,
    label: 'Mg',
  }
];

const cuartelesMap = [
  {
    "id": 1,
    "nombre": "Cuartel 25",
    "superficie": 10.37,
    "zona": 1,
    "coordenadas": [
      [-33.663721, -70.552011],
      [-33.668636, -70.551375],
      [-33.668485, -70.549437],
      [-33.663318, -70.550133],


    ],
  },
  {
    "id": 2,
    "nombre": "Cuartel 26",
    "superficie": 5.93,
    "zona": 2,
    "coordenadas": [
      [-33.663261, -70.549980], // Punto 1 arriba izq
      [-33.662924, -70.547925], // Punto 2 arriva der
      [-33.668474, -70.547186],
      [-33.668527, -70.549221],
    ],
  },

]

const sectoresMap = [
  {
    "id": 1,
    "superficie": 5.36,
    "nombre": "Sector 8",
    "cuartel": 1,
    "coordenadas": [
      [-33.672122, -70.546279],
      [-33.672198, -70.544776],
      [-33.674445, -70.544807],
      [-33.674370, -70.546400]
    ],
  },
  {
    "id": 2,
    "nombre": "Sector 9",
    "superficie": 5.01,
    "cuartel": 1,
    "coordenadas": [
      [-33.672248, -70.543153],
      [-33.672198, -70.544776],
      [-33.674445, -70.544807],
      [-33.674509, -70.543335]
    ],
  },
  {
    "id": 3,
    "nombre": "Sector 8",
    "superficie": 3.12,
    "cuartel": 2,
    "coordenadas": [
      [-33.674471, -70.546354],
      [-33.675986, -70.546461],
      [-33.676137, -70.543502],
      [-33.674673, -70.543320]
    ],
  },
  {
    "id": 4,
    "nombre": "Sector 10",
    "cuartel": 2,
    "superficie": 2.80987,
    "coordenadas": [
      [-33.675986, -70.546461],
      [-33.676137, -70.543502],
      [-33.677893, -70.543608],
      [-33.677842, -70.546506]
    ],
  },

]


//  TODO: elegir un mejor nombre
const Map = ({ listaUnidades, center, onSelectedUnidad }) => {

  const mapRef = useRef(null);

  // TODO: mostrar en el mapa cual unidad está seleccionada
  const [getSelectedPolygons, setSelectedPolygons] = useState([]);
  const [getOverMousePolygon, setOverMousePolygon] = useState({});

  // const latitude = -33.666118;
  // const longitude = -70.549456;

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.flyTo([center.latitude, center.longitude], 14);
    }
  }, [center])

  const handlerPolygonHover = (unidad) => {
    setOverMousePolygon(unidad);
  }

  const handlerPolygonMouseOut = (unidad) => {
    setOverMousePolygon({});
  }
  return (
    <MapContainer
      center={[center.latitude, center.longitude]}
      zoom={13}
      style={{ width: "100%", height: "400px", borderRadius: "6px" }}
      ref={mapRef}
    >
      <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
        attribution="MIIDO"

      />
      {listaUnidades.map((unidad) => (
        <FeatureGroup key={unidad.id} >
          <CustomPolygon
            index={unidad.id}
            unidad={unidad}
            onClick={() => {
              onSelectedUnidad(unidad);
            }}
            setMouseOver={handlerPolygonHover}
            setMouseOut={handlerPolygonMouseOut}
            selected={getSelectedPolygons.includes(unidad.id)}
          />
        </FeatureGroup>
      ))}
    </MapContainer>
  )
}

const BuyPreview = ({ products, surfaceSize }) => {

  //TODO: Mover esta lógica a un objeto de dominio

  const totalLitersOrKg = products.reduce((acc, product) => {
    return acc + ((product.CapacidadEnvase * product.quantity) / surfaceSize);
  }, 0);
  const totalCost = products.reduce((acc, product) => {
    return acc + (product.precio * product.quantity);
  }, 0);
  const totalCostPerHa = products.reduce((acc, product) => {
    return acc + Math.round((product.quantity * product.precio) / surfaceSize);
  }, 0);
  const totalCostPerKg = products.reduce((acc, product) => {
    return acc + Math.round((product.precio / product.CapacidadEnvase));
  }, 0);


  return (
    <Table>

      <TableHead>
        <TableRow>
          <TableCell>Proveedor</TableCell>
          <TableCell>Productos Seleccionados</TableCell>
          <TableCell>Total Litros o Kilos / Ha</TableCell>
          <TableCell>Total Costo </TableCell>
          <TableCell>Costo / Ha</TableCell>
          <TableCell>Costo por Kilo</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {
          products.map((product) => {
            return (
              <TableRow>
                <TableCell>{product.Proveedor}</TableCell>
                <TableCell>{product.Nombre}</TableCell>
                <TableCell>{((product.quantity * product.CapacidadEnvase) / surfaceSize).toFixed(2)} </TableCell>
                <TableCell>{`$ ${Math.round(product.quantity * product.precio)}`}</TableCell>
                <TableCell>{`$ ${Math.round((product.quantity * product.precio) / surfaceSize)}`}</TableCell>
                <TableCell>{`$ ${(product.precio / product.CapacidadEnvase)}`}</TableCell>
              </TableRow>
            )
          })
        }
        <TableRow>
          <TableCell><b>Totales</b></TableCell>
          <TableCell> </TableCell>
          <TableCell><b>{totalLitersOrKg.toFixed(1)}</b></TableCell>
          <TableCell><b>${totalCost}</b></TableCell>
          <TableCell><b>${totalCostPerHa}</b></TableCell>
          <TableCell><b>${totalCostPerKg}</b></TableCell>
        </TableRow>

      </TableBody>
    </Table>
  )
}

export default function FundoPage() {
  const navigate = useNavigate();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('precio');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [center, setCenter] = useState({ latitude: -33.666118, longitude: -70.549456 });


  const [seasonValues, setSeasonValues] = useState({});



  // product nutrient type (N=1, P=2, K=3, Ca=4, Mg=5)
  const [selectedNutrient, setSelectedNutrient] = useState(1);
  const [selectedUnits, setSelectedUnits] = useState(cuartelesMap);
  const [products, setProducts] = useState([]);
  const [productRows, setProductRows] = useState([]);

  const [optimalProducts, setOptimalProducts] = useState([]);

  const [filledN, setFilledN] = useState(0);
  const [filledP, setFilledP] = useState(0);
  const [filledK, setFilledK] = useState(0);
  const [filledCa, setFilledCa] = useState(0);
  const [filledMg, setFilledMg] = useState(0);
  const [productionGoal, setProductionGoal] = useState(0);
  const [Ncoefficient, setNcoefficient] = useState(0);
  const [Pcoefficient, setPcoefficient] = useState(0);
  const [Kcoefficient, setKcoefficient] = useState(0);
  const [Cacoefficient, setCacoefficient] = useState(0);
  const [Mgcoefficient, setMgcoefficient] = useState(0);
  const [currentUnit, setCurrentUnit] = useState({ id: 0 });

  // TODO: implementar pasos con enum para que sea mas semantico
  const [step, setStep] = useState(0); // 0 for quote, 1 for preview, 2 for buy

  const [selectedProducts, setSelectedProducts] = useState([]);

  const chartRef = useRef(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!localStorage.getItem('jwt')) {
    navigate('/login', { replace: true });
  }
  const token = localStorage.getItem('jwt');
  const headers = {
    auth: token,
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
  };

  /**
   * get products from backend
   */
  useEffect(() => {
    const getProductos = async () => {
      const response = await fetch(`${url}/productos`, { headers });
      const dataProducts = await response.json();
      setProducts(dataProducts.data);
    }
    getProductos();
  }, []);

  useEffect(() => {

    const productRows = products.map((product) => {
      // get provider name from provider name
      var provider = '';
      var tipo_aplicacion = '';
      if (product.proveedor_id === 1) {
        provider = 'Bayer'
      } else if (product.proveedor_id === 2) {
        provider = 'Adama'
      } else if (product.proveedor_id === 3) {
        provider = 'Agropuelma'
      }
      else if (product.proveedor_id === 3) {
        provider = 'Agropuelma'
      }
      else if (product.proveedor_id === 4) {
        provider = 'CYT';
      }
      else if (product.proveedor_id === 5) {
        provider = 'VITRA';
      }
      else if (product.proveedor_id === 6) {
        provider = 'NUTRIEN';
      }
      else if (product.proveedor_id === 7) {
        provider = 'KERLEY';
      }
      else if (product.proveedor_id === 8) {
        provider = 'ANAGRA-VITRA';
      }
      else if (product.proveedor_id === 9) {
        provider = 'ANAGRA-MAVAR';
      }
      else if (product.proveedor_id === 10) {
        provider = 'MYV';
      }
      else if (product.proveedor_id === 11) {
        provider = 'MYV-CHEMIE';
      }
      else if (product.proveedor_id === 12) {
        provider = 'SYTNGENTA-IBITERRA';
      }
      else if (product.proveedor_id === 13) {
        provider = 'QUIMETAL';
      }
      if (product.tipo_aplicacion_id === 1) {
        tipo_aplicacion = 'Liquido'
      } else if (product.tipo_aplicacion_id === 2) {
        tipo_aplicacion = 'Granulado'
      } else if (product.tipo_aplicacion_id === 3) {
        tipo_aplicacion = 'Soluble'
      } else if (product.tipo_aplicacion_id === 4) {
        tipo_aplicacion = 'Floable'
      }

      // return product displayable data.
      return {
        id: product.id,
        Proveedor: provider,
        Nombre: product.nombre,
        N: ((product.n.toFixed(1) / 100) * product.capacidad_envase).toFixed(1),
        P: ((product.p.toFixed(1) / 100) * product.capacidad_envase).toFixed(1),
        K: ((product.k.toFixed(1) / 100) * product.capacidad_envase).toFixed(1),
        Ca: ((product.ca.toFixed(1) / 100) * product.capacidad_envase).toFixed(1),
        Mg: ((product.mg.toFixed(1) / 100) * product.capacidad_envase).toFixed(1),
        precio: product.precio,
        tipo_producto: product.tipo_producto_id,
        tipo_aplicacion: tipo_aplicacion,
        CapacidadEnvase: product.capacidad_envase,
        quantity: 0
      }
    });
    setProductRows(productRows);
  }, [products]);

  /**
   * handle change product quantity
   * @param {productRow} product object with product visible data
   * @param {Integer} newQuantity new quantity of product
   */
  const handleChangeProductQuantity = (product, newQuantity) => {
    // search product with quantity change
    const newProductRows = productRows.map((productRow) => {
      if (productRow.id === product.id) {
        // when find the product, set th new quantity
        return {
          ...productRow,
          quantity: newQuantity
        }
      }
      // return the product without changes
      return productRow;
    });



    // calculate filled nutrients
    const filledN = newProductRows.reduce((acc, productRow) => {
      return acc + (productRow.N * productRow.quantity);
    }, 0);
    const filledP = newProductRows.reduce((acc, productRow) => {
      return acc + (productRow.P * productRow.quantity);
    }, 0);
    const filledK = newProductRows.reduce((acc, productRow) => {
      return acc + (productRow.K * productRow.quantity);
    }, 0);
    const filledCa = newProductRows.reduce((acc, productRow) => {
      return acc + (productRow.Ca * productRow.quantity);
    }, 0);
    const filledMg = newProductRows.reduce((acc, productRow) => {
      return acc + (productRow.Mg * productRow.quantity);
    }, 0);

    // set filled nutrients
    setFilledN(filledN);
    setFilledP(filledP);
    setFilledK(filledK);
    setFilledCa(filledCa);
    setFilledMg(filledMg);

    // set new product rows
    setProductRows(newProductRows);
  }

  useEffect(() => {
    const socket = new window.WebSocket("wss://data.miido.cl");
    socket.addEventListener('open', (event) => {
      socket.send(`optimize:${seasonValues.limite_n}:${seasonValues.limite_p}:${seasonValues.limite_k}:${seasonValues.limite_mg}:${seasonValues.limite_ca}`);
    });

    socket.addEventListener('message', (event) => {
      console.log(event.data);
      const response = JSON.parse(event.data).data;
      setOptimalProducts(response);
    });

  }, [seasonValues]);


  useEffect(() => {
    setSelectedProducts(productRows.filter((product) => product.quantity > 0));
  }, [productRows])

  const normalise = (value) => ((value - 0) * 100) / (9);

  const handleExportClick = () => {
    const cost = selectedProducts.reduce((acc, product) => {
      return acc + (product.precio * product.quantity);
    }, 0);


    const excel = [];

    selectedProducts.forEach((product) => {

      // TODO: llenar con data real en vez de esta data de ejemplo
      sectoresMap.filter((sector) => sector.cuartel === currentUnit.id).forEach((sector) => {
        excel.push({
          Valle: "Maipo",
          Fundo: "Santa Isabel",
          Centro: "1334",
          Fecha: new Date().toLocaleDateString(),
          Caseta: "C005",
          EquipoSap: "B018",
          Sector: sector.nombre,
          Superficie: sector.superficie,
          Ficha: "15",
          Cuartel: currentUnit.nombre,
          Conduccion: "Vina Espaldera",
          Variedad: "Chardonnay",
          Aptitud: "Premium",
          Rendimiento: productionGoal,
          Producto: product.Nombre,
          UM: product.tipo_aplicacion,
          CantidadSacos: product.quantity,
          TotalKilos: product.quantity * product.CapacidadEnvase,
          Condicionado: "No",
          Observaciones: " ",
          N: product.N * product.quantity,
          P: product.P * product.quantity,
          K: product.K * product.quantity,
          Mg: product.Mg * product.quantity,
          Ca: product.Ca * product.quantity,
          Tipo: "Otros",
        });
      });

      fetch(`${url}/carro_de_compras/post/cuartel/${currentUnit.id}`, {
        headers: headers,
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          "cuartel_id": currentUnit.id,
          "productos": selectedProducts
        })
      }
      )

    });

    const newWorkbook = XLSX.utils.book_new();
    const newWorksheet = XLSX.utils.json_to_sheet(excel);
    XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, "Carro de compras");
    XLSX.writeFile(newWorkbook, "Carro de compras.xlsx");
    alert(`Carro de compras guardado con éxito. reidreccionando...`);

    setTimeout(() => {
      window.location.href = "/dashboard/Aplicación";
    }, 1000);
  }

  const handleNextStepClick = () => {
    // TODO: stop on step 2
    setStep(step + 1);  // move to next step
  }

  const handlePreviousStepClick = () => {
    setStep(step - 1);
  }

  // TODO: cambiar nombre por algo mas descriptivo
  const handleSelectedUnidad = (unidad) => {

    // mark selected unit
    setCurrentUnit(unidad);

    // get data from previous calculations
    fetch(`${url}/calculos/cuartel/${unidad.id}`, {
      headers: headers
    }).then(res => res.json())
      .then(res => {
        if (res.data.length > 0) {
          setNcoefficient(res.data[0].coeficiente_n);
          setPcoefficient(res.data[0].coeficiente_p);
          setKcoefficient(res.data[0].coeficiente_k);
          setCacoefficient(res.data[0].coeficiente_ca);
          setMgcoefficient(res.data[0].coeficiente_mg);
          setProductionGoal(res.data[0].objetivo_produccion);
          /* setSeasonValues({
            limite_n: res.data.reduce((prev, act) => prev + act.limite_n, 0),
            limite_p: res.data.reduce((prev, act) => prev + act.limite_p, 0),
            limite_k: res.data.reduce((prev, act) => prev + act.limite_k, 0),
            limite_ca: res.data.reduce((prev, act) => prev + act.limite_ca, 0),
            limite_mg: res.data.reduce((prev, act) => prev + act.limite_mg, 0)
          }); */
          setSeasonValues({
            limite_n: res.data[0].limite_n,
            limite_p: res.data[0].limite_p,
            limite_k: res.data[0].limite_k,
            limite_ca: res.data[0].limite_ca,
            limite_mg: res.data[0].limite_mg
          });

        } else {
          setSeasonValues({})
          alert(`Unidad ${unidad.id} no seteada `);
        }
      });

  };

  //CHART
  useEffect(() => {
    Chart.register(ChartDataLabels);

    const necesidadData = Object.values(seasonValues);
    const abarcadoData = [filledN, filledP, filledK, filledCa, filledMg];

    const chart = new Chart(chartRef.current, {
      type: "bar",
      data: {
        labels: ["N", "P", "K", "Ca", "Mg"],
        datasets: [
          {
            label: 'Unidades por superficie',
            data: necesidadData,
            borderColor: '#d9d9d9',
            barThickness: 30,
            borderRadius: 25,
          },
          {
            label: 'Unidades aportadas por productos',
            data: abarcadoData,
            borderColor: '#87bdb9',
            backgroundColor: '#87bdb9',
            barThickness: 30,
            borderRadius: 25,
            datalabels: {
              color: 'white', // Cambia este valor al color que desees
            },
          }
        ]
      },
      options: {
        plugins: {
          datalabels: {
            // Cambia la posición de las etiquetas si es necesario
            // Cambia la alineación de las etiquetas si es necesario
            font: {
              size: 16,
              weight: 'bold', // Ajusta el tamaño de la fuente aquí
              // Ajusta el color de la fuente aquí
            }
          }
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
            reverse: true,
            grid: { display: false }
          },
          x: {
            grid: { display: false },
          }
        },
        responsive: true,
      },
    });
    return () => {
      chart.destroy();
    };
  }, [seasonValues, filledN, filledCa, filledK, filledMg, filledP]);

  return (
    <Container maxWidth={false} >

      {/* map */}
      <Card variant="outlined" style={{ padding: "16px" }} >
        <Grid container spacing={2}>
          {/* Map controls */}
          <Grid item xs={12}>
            <Button
              variant={'contained'}
              sx={{ color: 'white' }}
              style={{
                backgroundColor: 'rgb(81, 160, 154)',
                marginRight: '5px'
              }}
              onClick={() => setSelectedUnits(sectoresMap)}
            >
              Sectores
            </Button>
            <Button
              variant={'contained'}
              sx={{ color: 'white' }}
              style={{
                backgroundColor: 'rgb(81, 160, 154)',
                margin: '0px 5px'
              }}
              onClick={() => setSelectedUnits(cuartelesMap)}
            >
              Cuarteles
            </Button>
            <Button
              variant={'contained'}
              sx={{ color: 'white' }}
              style={{
                backgroundColor: 'rgb(81, 160, 154)',
                margin: '0px 5px'
              }}
              onClick={() => {
                setCenter({ ...center })
              }}
            >
              Centrar
            </Button>
          </Grid>
          {/* / Map controls */}

          {/* Map canvas */}
          <Grid item xs={6}>
            <Map
              listaUnidades={selectedUnits}
              center={center}
              onSelectedUnidad={handleSelectedUnidad}
            ></Map>
          </Grid>
          {/* /Map canvas */}

          {/* Chart Canvas */}
          <Grid item xs={6}>
            <canvas ref={chartRef} />

            <Stack spacing={10} direction="row">
              <CircularProgressWithLabel variant="determinate" value={normalise(Ncoefficient)} display={Ncoefficient} type={'N'} />
              <CircularProgressWithLabel variant="determinate" value={normalise(Pcoefficient)} display={Pcoefficient} type={'P'} />
              <CircularProgressWithLabel variant="determinate" value={normalise(Kcoefficient)} display={Kcoefficient} type={'K'} />
              <CircularProgressWithLabel variant="determinate" value={normalise(Mgcoefficient)} display={Mgcoefficient} type={'Mg'} />
              <CircularProgressWithLabel variant="determinate" value={normalise(Cacoefficient)} display={Cacoefficient} type={'Ca'} />
            </Stack>

          </Grid>
          {/* /Chart Canvas */}

        </Grid>
      </Card>
      {/* /map */}

      {/* suggestion */}
      {
        step === 0 && Object.keys(optimalProducts).length > 0 &&
        <Card style={{ 'marginTop': '16px' }}>
          <Alert severity='info'> Aquí una lista para optimizar tu compra: <br /> {optimalProducts.map((product) => {
            return (
              <div>
                <p> Producto: {product.producto}  <br /> Cantidad: {product.cantidad} </p>
              </div>)
          })}

          </Alert>
        </Card>
      }
      {/* /suggestion */}

      {/* table */}
      {step === 0 &&
        <Card style={{ padding: "10px", marginTop: "16px" }}>
          {/* table controls */}
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <ToggleButton selectedButton={selectedNutrient} setSelectedButton={setSelectedNutrient} />
            </Grid>
            <Grid item>
              <Button
                id="button-export"
                style={{ backgroundColor: 'rgb(81, 160, 154)', color: 'white' }}
                variant="contained"
                onClick={handleNextStepClick}
              >
                Siguiente
              </Button>
            </Grid>
          </Grid>
          {/* /table controls */}

          <TableContainer component={Paper} sx={{ maxHeight: '40vh', mt: 1.5, mb: 2, overflow: 'auto' }}>
            <Table sx={{ minWidth: 750 }} aria-label="enhanced table">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={productRows.length}
              />
              <TableBody>
                {stableSort(
                  productRows.filter(
                    (product) => product.tipo_producto === selectedNutrient
                  ),
                  getComparator(order, orderBy)
                ).map((product, index) => {
                  const rowColor = getRowColor(product);
                  return (
                    <TableRow hover tabIndex={-1} key={index} style={{ backgroundColor: `${rowColor}` }}>
                      <InputNumber label="sacos" value={product.quantity} onChange={(quantity) => {
                        handleChangeProductQuantity(product, quantity)
                      }} />

                      {headCells.map((headCell) => (
                        <TableCell key={headCell.id}>
                          {product[headCell.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={productRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>

        </Card>
      }
      {/* /table */}

      {step === 1 &&
        <Card style={{ padding: '10px', marginTop: '16px' }}>
          {/* table controls */}
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Button
                id="button-export"
                style={{ backgroundColor: 'rgb(81, 160, 154)', color: 'white' }}
                variant="contained"
                onClick={handlePreviousStepClick}
              >
                Anterior
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="button-export"
                style={{ backgroundColor: 'rgb(81, 160, 154)', color: 'white', textTransform: 'none' }}
                variant="contained"
                onClick={handleExportClick}
              >
                Guardar y exportar a Excel
              </Button>
            </Grid>
          </Grid>
          {/* /table controls */}
          <BuyPreview products={selectedProducts} surfaceSize={cuartelesMap.filter((cuartel) => cuartel.id === currentUnit.id)[0]?.superficie} ></BuyPreview>
        </Card>
      }


    </Container>

  );
}
