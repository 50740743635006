import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { Button, Stack, Input } from "@mui/material";
import Swal from 'sweetalert2';
import { set, toNumber } from "lodash";
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { marker } from "leaflet";
import './mapDrawer.css';
import { area } from '@turf/turf';
import CustomPolygon from '../components/polygon/index';


import L from 'leaflet';

const maxHa = 5;

const cultivos = {
  "Paltos": "Paltos",
  "Cerezas": "Cerezas",
  "Vides": "Vides",
  "Nogales": "Nogales",
  "Ciruelos": "Ciruelos",
  "Duraznos": "Duraznos",
  "Manzanos": "Manzanos",
  "Perales": "Perales",
  "Cítricos": "Cítricos",
  "Kiwi": "Kiwi",
  "Hortalizas": "Hortalizas",
  "Flores": "Flores",
  "Otros": "Otros"
};

let html = `
<input id="swal-id" class="swal2-input" placeholder="ID">
<select id="swal-cultivo" class="swal2-input">
  <option value="">Selecciona un cultivo</option>
`;

for (let key in cultivos) {
  html += `<option value="${key}">${cultivos[key]}</option>`;
}

html += `</select>`;
 


const Map = ({  }) => {
    const [center, setCenter] = useState({ latitude: -33.666118, longitude: -70.549456 });
    const mapRef = useRef(null);
    //mapLayers
    const [mapLayers, setMapLayers] = useState([]);
    // TODO: mostrar en el mapa cual unidad está seleccionada
    const [getSelectedPolygons, setSelectedPolygons] = useState([]);
    const [getOverMousePolygon, setOverMousePolygon] = useState({});
    const [sumHa, setSumHa] = useState(0);
    const [runCount, setRunCount] = useState(0);
    const [polygon, setPolygon] = useState([]);
    const newIcon = L.icon({
      iconUrl: 'https://leafletjs.com/examples/custom-icons/leaf-green.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

  
    // const latitude = -33.666118;
    // const longitude = -70.549456;
    useEffect(() => {
      const mapLayersJSON = JSON.stringify(mapLayers);
      localStorage.setItem('mapLayers', mapLayersJSON);
      setPolygon(mapLayers);
    }, [mapLayers]);

  
    useEffect(() => {
      const map = mapRef.current;
      const provider = new OpenStreetMapProvider();
      const searchControl = new GeoSearchControl({
        provider: provider,
        position: 'topleft',
        className: 'leaflet-bar',
        showMarker: true, // optional: true|false  - default true
        searchLabel: 'Buscar...',
        autoComplete: true, // optional: true|false  - default true
        autoCompleteDelay: 250, // optional: number      - default 250
        marker: {
          icon: newIcon,
        }
      });
      if (map) {
        map.flyTo([center.latitude, center.longitude], 14);
        map.addControl(searchControl);
      }
      // debuggea el que no carga la barra de busqueda xd
      if (runCount < 1) {
        setRunCount(runCount + 1);
      }
    }, [runCount]);
    

    const _onCreate = (e) => {
        const { layerType, layer } = e;
        //revisar si es mejor calcular las ha dados los puntos o si el cliente lo ingresa (yo creo que mejor los ingrese el cliente)
        if (layerType === "polygon") {
          const latLngs = layer.getLatLngs()[0];
          const coordinates = latLngs.map(latLng => [latLng.lng, latLng.lat]);
          coordinates.push(coordinates[0]);
          const polygon = {
            type: 'Polygon',
            coordinates: [coordinates]
          };
          const areaHa = parseFloat((area(polygon)/ 10000).toFixed(2));
          setSumHa(prevSumHa => {
            const newTotal = prevSumHa + areaHa;
            console.log("newTotal", newTotal);
            if (newTotal > maxHa) {
              layer.setStyle({ color: "red" });
              Swal.fire({
                title: "Superficie excede el máximo permitido",
                text: `La superficie total es de ${newTotal} [ha] y el máximo permitido es de ${maxHa} [ha]`,
                icon: "error",
              });
              return prevSumHa; // No incrementamos sumHa si excede maxHa
            } else {
              layer.setStyle({ color: "blue" });
            Swal.fire({
              title:  `Ingrese el identificador y cultivo de la unidad 
              Superficie : ${areaHa} [ha]`,
              html: html,
              preConfirm: () => {
                const id = document.getElementById('swal-id').value;
                const cultivo = document.getElementById('swal-cultivo').value;
                return [id, cultivo];
              },
              showCancelButton: true,
              confirmButtonText: "Guardar",
            }).then((result) => {
              if (result.isConfirmed) { 
                const [id, cultivo] = result.value;
                setMapLayers((layers) => [
                  ...layers,
                  { id: toNumber(id), superficie: areaHa, puntos: layer.getLatLngs()[0], cultivo: cultivo},
                ]);
              }
            });
            return newTotal; // Incrementamos sumHa si no excede maxHa
            }
          });
        }
        
      };
  
    
    return (
        <>
        <MapContainer
                center={[center.latitude, center.longitude]}
                zoom={13}
                style={{ width: "100%", height: "80%", borderRadius: "6px" }}
                ref={mapRef}
            >
                <FeatureGroup>
                
                <EditControl
                  position="topright"
                  onCreated={_onCreate}
                  onEdited={(e) => {
                  console.log(e);
                  }}
                  onDeleted={(e) => {
                  console.log(e);
                  }}
                  draw={{
                      rectangle: false,
                      polyline: true,
                      circle: false,
                      circlemarker: false,
                      marker: false,
                      polygon:{
                          allowIntersection: false,
                          drawError: {
                              color: '#e1e100',
                              message: '<strong>Error:<strong> los bordes no pueden cruzarse!',
                              timeout: 1000
                          },
                          shapeOptions: {
                              color: '#c6ccc8'
                          }
                      }
                  }}
                />
                
                </FeatureGroup>
                <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                attribution="MIIDO"
                />
                
            </MapContainer>
            
            
        </>
      
      
    )
  }



export default Map;