// @mui
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Tab,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import { Helmet } from "react-helmet-async";
import Table from '@mui/material/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Tabs from '@mui/material/Tabs';
import LinearProgress from '@mui/material/LinearProgress';
import { visuallyHidden } from '@mui/utils';
// components
// sections
import React from "react";
import { useEffect, useState } from "react";
import { Line, Bar } from "react-chartjs-2";
import dotenv from 'dotenv';
import "./form.css"
import Swal from "sweetalert2";
import { writeFileXLSX, utils } from "xlsx";

dotenv.config();
const url = process.env.REACT_APP_BASE_URL;

// --------------------- tareas ----------------------
//  id   Empresa_id  User_id  titulo   fecha  fecha_termino   estado  prioridad   tipo_tarea  id_imagen

// graficos

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'estado') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  } else if (orderBy === 'prioridad') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  } else if (orderBy === 'fecha') {
    if (new Date(b[orderBy]) < new Date(a[orderBy])) {
      return -1;
    }
    if (new Date(b[orderBy]) > new Date(a[orderBy])) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
};

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export default function DashboardTaskLCP() {
  if (!localStorage.getItem('jwt')) {
    window.location.href = "/login";
  }
  const [barData, setBarData] = useState({labels: [], datasets: []});
  const [lineData, setLineData] = useState({labels: [], datasets: []});
  const [cantidadHoras, setCantidadHoras] = useState(0);
  const [calculos, setCalculos] = useState([]);
  const [employee, setEmployee] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [filteredTareas, setFilteredTareas] = useState([]);
  const [issues, setIssues] = useState([]);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [jwt, setJwt] = useState("");
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false); // Estado para el popup
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('prioridad');
  const [tipoRegistro, setTipoRegistro] = useState(5);
  const [selectedImage, setSelectedImage] = useState(null); // Estado para la imagen seleccionada
  const [tabValue, setTabValue] = useState(1);
  const [tareas, setTareas] = useState([]);
  const [total, setTotal] = useState(0);
  const [valorHora, setValorHora] = useState(0);
  
  const jwt_ = localStorage.getItem('jwt');
  const data = jwt_.split('.')[1];
  const user = JSON.parse(atob(data));
  const User_id = user.User_id;

  const handleExcel = (data) => {
    const dispatches = data.filter(item => item.tipo_registro === "Despacho");
    const income = data.filter(item => item.tipo_registro === "Ingreso");

    const newDispatches = dispatches.map(item => ({
      empleado: item.empleado,
      detalle: item.detalle,
      fecha: new Date(item.fecha).toLocaleDateString(),
      sitio_disposicion: item.sitio_disposicion,
      cantidad: item.cantidad,
      precio: item.precio_de_compra
    }));

    const newIncome = income.map(item => ({
      empleado: item.empleado,
      detalle: item.detalle,
      fecha: new Date(item.fecha).toLocaleDateString(),
      origen: item.origen,
      cantidad: item.cantidad
    }));

    const dispatchesHeaders = ["Empleado", "Detalle", "Fecha", "Sitio de Disposición", "Cantidad [kg]", "Precio"];
    const incomeHeaders = ["Empleado", "Detalle", "Fecha", "Origen", "Cantidad [kg]"];
    
    const worksheet_dispatches = utils.json_to_sheet(newDispatches);
    const worksheet_income = utils.json_to_sheet(newIncome);

    const dateCellStyle = { numFmt: 'yyyy-mm-dd' };

    const applyDateFormat = (worksheet, columnLetter) => {
      const range = utils.decode_range(worksheet['!ref']);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        const cellAddress = `${columnLetter}${row + 1}`;
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].z = dateCellStyle.numFmt;
        }
      }
    };

    applyDateFormat(worksheet_dispatches, 'C');
    applyDateFormat(worksheet_income, 'C');

    utils.sheet_add_aoa(worksheet_dispatches, [dispatchesHeaders], {origin: "A1"});
    utils.sheet_add_aoa(worksheet_income, [incomeHeaders], {origin: "A1"});

    const adjustColumnWidths = (worksheet, headers) => {
      const colWidths = headers.map(header => header.length);
      for (let R = 2; R <= worksheet['!ref'].split(':')[1].match(/\d+/)[0]; R++) {
        headers.forEach((_, C) => {
          const cell = worksheet[`${String.fromCharCode(65 + C)}${R}`];
          if (cell && cell.v) {
              colWidths[C] = Math.max(colWidths[C], String(cell.v).length);
          }
        });
      }
      worksheet['!cols'] = colWidths.map(width => ({wch: width + 2}));
    };

    adjustColumnWidths(worksheet_dispatches, dispatchesHeaders);
    adjustColumnWidths(worksheet_income, incomeHeaders);

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet_dispatches, 'Despachos');
    utils.book_append_sheet(workbook, worksheet_income, 'Ingresos');

    writeFileXLSX(workbook, 'registros.xlsx', {cellStyles: true});
  }

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };
  
  const rgbToRgbaString = (rgb, opacity) => {
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
  };

  const fixDate = (oldDate, type) => {
    if (oldDate === "Sin asignar") return oldDate;
    const date = new Date(oldDate);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Los meses en JavaScript empiezan en 0
    const year = date.getUTCFullYear();
    if (type === "Swal") return `${year}-${month}-${day}`;
    return `${day}-${month}-${year}`;
  };

  const filterLineData = (data_issues) => {
    try {
      const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const today = new Date();
      const dates = [];
      for (let i = 7; i > 0; i--) {
        const pastDate = new Date(today);
        pastDate.setDate(today.getDate() - i);
        dates.push(formatDate(pastDate));
      }
  
      dates.push(formatDate(today));
  
      for (let i = 1; i <= 2; i++) {
        const futureDate = new Date(today);
        futureDate.setDate(today.getDate() + i);
        dates.push(formatDate(futureDate));
      }
  
      for (let i = 0; i < data_issues.length; i++) {
        for (let j = 0; j < dates.length; j++) {
          if (formatDate(new Date(data_issues[i].fecha)) === dates[j]) {
            data[j] += 1
          }
        }
      }
  
      const borderWidth = 1;
      const datasets = [
        {
          label: 'Registros',
          data: data,
          borderWidth: borderWidth,
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)"
        },
      ];
      setLineData({
        labels: dates,
        datasets: datasets,
      });
    } catch (error) {
      console.error('Error al procesar los datos de línea:', error);
    }
  };

  const filterBarData = (data_issues) => {
    try {
      const issues_name = [];
      const issues_amount = [];

      const count = {};

      data_issues.forEach(item => {
        if (item.tipo_registro === null) {
          item.tipo_registro = 'Sin categoria';
        }
        const categoria = item.tipo_registro;
        count[categoria] = (count[categoria] || 0) + 1;
      });

      for (const [key, value] of Object.entries(count)) {
        issues_name.push(key);
        issues_amount.push(value);
      }
      
      const hexColors = ['#38507E', '#51A09A', '#C2DB64', '#2F3D44'];

      const backgroundColor = {};
      const borderColor = {};
      const borderWidth = 1;

      issues_name.forEach((issue, index) => {
        const hexColor = hexColors[index % hexColors.length];
        const rgbColor = hexToRgb(hexColor);
        backgroundColor[issue] = rgbToRgbaString(rgbColor, 0.5);
        borderColor[issue] = rgbToRgbaString(rgbColor, 1);
      });

      const datasets = [
        {
          label: 'Cantidad',
          data: issues_amount,
          borderWidth: borderWidth,
          fill: true,
          backgroundColor: issues_name.map(issue => backgroundColor[issue]),
          borderColor: issues_name.map(issue => borderColor[issue]),
          barPercentage: 0.5,
          maxBarThickness: 50,
          minBarLength: 2,
        },
      ];

      setBarData({
        labels: issues_name,
        datasets: datasets,
      });
    } catch (error) {
      console.error('Error al procesar los datos de línea:', error);
    }
  };

  const formatDate = (inputDate) => {
    const dateString = inputDate instanceof Date ? inputDate.toISOString() : inputDate;
    const [year, month, day] = dateString.split('T')[0].split('-');
    return `${day}-${month}`;
  };

  const handleButton = () => {
    console.log("valorHora", valorHora);
    console.log("cantidadHoras", cantidadHoras);
    console.log("total", total);
    setCalculos([...calculos, { valorHora, cantidadHoras, total }]);
  };

  const handleCantidadHoras = (event) => {
    setCantidadHoras(event.target.value);
    setTotal(event.target.value * valorHora);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "¿Estas seguro de eliminar este registro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${url}/registrosLCP/delete/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'auth': jwt
          },
        }).then(response => response.json())
        .then(data => {
          setIssues(issues.filter(issue => issue.id !== id));
          setFilteredIssues(issues.filter(issue => issue.id !== id));
        })
        .catch(error => {
          console.error('Error:', error);
        });

        Swal.fire({
          title: "Exito al eliminar registro",
          text: "El registro ha sido eliminado.",
          icon: "success"
        });
      }
    });
  };

  const handleEmployee = (e) => {
    if (e.target.value === 0) {
        setEmployee(0);
        setFilteredTareas(tareas);
        return;
    }
    setEmployee(e.target.value);
  };

  const handleTipoRegistro = (e) => {
    if (e.target.value === 5) {
        setTipoRegistro(5);
        setFilteredIssues(issues);
        return;
    }
    setTipoRegistro(e.target.value);
  };

  const handleImageClick = (url) => {
    setSelectedImage(url);
    setOpen(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleValorHora = (event) => {
    setValorHora(event.target.value);
    setTotal(event.target.value * cantidadHoras);
  };

  const renderTable = (issues) => {
    return issues.map(issue => {
      return (
        <TableRow key={issue.id}>
          <TableCell>{issue.empleado}</TableCell>
          <TableCell>{issue.detalle}</TableCell>
          <TableCell>{fixDate(issue.fecha, "")}</TableCell>
          <TableCell>{issue.tipo_registro}</TableCell>
          <TableCell>{issue.cantidad}</TableCell>
          {/* <TableCell>{issue.precio_de_compra}</TableCell> */}
          <TableCell>
            {issue.url && issue.url !== "null" ? (
              <img
                src={issue.url}
                alt="imagen"
                style={{ width: 90, height: 130, cursor: 'pointer' }}
                onClick={() => handleImageClick(issue.url)}
              />
            ) : (
              <Typography>No hay imagen disponible</Typography>
            )}
          </TableCell>
          
          <TableCell>
            <IconButton onClick={() => handleDelete(issue.id)}>
              <RemoveIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  function setupOffset() {
    const now = new Date();
    const offsetMinutes = now.getTimezoneOffset();
    const offsetHours = offsetMinutes / 60;
    const offsetDirection = offsetHours > 0 ? '-' : '+';
    const offsetFormatted = `${offsetDirection}${Math.abs(offsetHours)}`;
    localStorage.setItem("offset", parseInt(offsetFormatted));
  };

  function setupJwt() {
    const jwt = localStorage.getItem('jwt');
    setJwt(jwt);
    const decoded = Buffer.from(jwt.split('.')[1], 'base64').toString('ascii');
    const data = JSON.parse(decoded);
    const response = {
      "jwt": jwt,
      "Empresa_id": data.Empresa_id
    }
    return response;
  };

  function setupWebSocket(info) {
    const uri = 'wss://websocket-eqjlrpwjoa-uc.a.run.app';
    const connection = new WebSocket(uri);

    connection.addEventListener('open', () => {
      console.log('Connected to the WebSocket server');
    });

    connection.addEventListener('message', (event) => {
      const data = event.data;
      console.log('Received the defined message:', data);
      const refreshed_id = data.split(':')[1].trim();
      if (parseInt(info.Empresa_id) === parseInt(refreshed_id)) {
          fetch(`${url}/registrosLCP`)
          .then(response => response.json())
          .then(data => {
            setLoading(false);
            setIssues(data);
            setFilteredIssues(data);
            filterBarData(data);
            filterLineData(data);
          })
          .catch(error => {
            console.error('Error al obtener los datos:', error);
          });
      }
    });

    connection.addEventListener('close', () => {
      setupWebSocket();

    });
  };

  useEffect(() => {
    setupOffset();
    const info = setupJwt();
    setupWebSocket(info);
    fetch(`${url}/registrosLCP`)
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        setIssues(data);
        setFilteredIssues(data);
        filterBarData(data);
        filterLineData(data);
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });
    fetch(`${url}/usuarios/empresa/${User_id}`)
      .then(response => response.json())
      .then(data => {
          setEmployees(data.usuarios);
          setEmployee(0);
      })
      .catch(error => {
          console.error('Error al obtener los datos:', error);
      });
  }, []);

  const fixTipoRegistro = (tipo_registro) => {
    if (tipo_registro === "Ingreso") {
      return 0;
    } else {
      return 1;
    }
  }

  useEffect(() => {
    if (employee === 0 && tipoRegistro === 5) {
      setFilteredIssues(issues)
      return;
    } else if (employee !== 0  && tipoRegistro === 5) {
      const newIssues = issues.filter(issue => issue.User_id === employee);
      setFilteredIssues(newIssues);
      return;
    } else if (employee === 0 && tipoRegistro !== 5) {
      const newIssues = issues.filter(issue => fixTipoRegistro(issue.tipo_registro) === tipoRegistro);
      setFilteredIssues(newIssues);
      return;
    } else if (employee !== 0 && tipoRegistro !== 5) {
      let newIssues = issues.filter(issue => fixTipoRegistro(issue.tipo_registro) === tipoRegistro);
      newIssues = newIssues.filter(issue => issue.User_id === employee);
      setFilteredIssues(newIssues);
      return;
    }
}, [employee, tipoRegistro, issues]);

  return (
    <div>
      <Helmet>
        <title> Miido </title>
      </Helmet>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Registros" value={1} />
      </Tabs>
      {tabValue === 1 &&
        <Card sx={{ px: 2, py: 1.5, height: "40vh", display: 'flex', flexDirection: 'column', color: '#3C4447' }}>
          <Typography variant="h4">Dashboard</Typography>
          <Grid container spacing={4} direction="row" style={{ flexGrow: 1 }}>
            <Grid item xs={12} sm={4}>
              {loading ? (
                <LinearProgress />
              ) : (
                <Line
                  data={lineData}
                  height="100%"
                  width="100%"
                  options={{
                    scales: {
                      x: {
                        grid: {
                          display: false
                        }
                      },
                      y: {
                        grid: {
                          display: false
                        },
                        min: 0,
                        ticks: {
                          callback: function (value) {
                            return Number.isInteger(value) ? value : null;
                          }
                        }
                      }
                    },
                    maintainAspectRatio: false
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
            {loading ? (
                <LinearProgress />
              ) : (
                <Bar
                  data={barData}
                  height="100%"
                  width="100%"
                  options={{
                    scales: {
                      x: {
                        grid: {
                          display: false
                        }
                      },
                      y: {
                        grid: {
                          display: false
                        },
                        ticks: {
                          callback: function (value) {
                            return Number.isInteger(value) ? value : null;
                          }
                        }
                      }
                    },
                    maintainAspectRatio: false,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Card>}
      {tabValue === 2 &&
        <Card sx={{ px: 2, py: 1.5, height: "40vh", display: 'flex', flexDirection: 'column' }}>
          <Grid container spacing={2} direction="row" style={{ flexGrow: 1 }}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h4">Cálculos</Typography>
              <Typography variant="h6">Valor Hora</Typography>
              <Input placeholder="Valor Hora" value={valorHora} onChange={handleValorHora} />
              <Typography variant="h6">Cantidad Horas</Typography>
              <Input placeholder="Cantidad Horas" value={cantidadHoras} onChange={handleCantidadHoras} />
              <Typography variant="h6">Valor Total : {total}</Typography>
              <Button variant="contained" onClick={handleButton}>Agregar Cálculo</Button>
            </Grid>
          </Grid>
        </Card>}
      {tabValue === 1 && (
        <Card sx={{ px: 2.5, py: 2.5 }} style={{ marginTop: '5px' }}>
          <Select sx={{marginRight: 2}} id="employees" value={employee} onChange={e => handleEmployee(e)}>
            <MenuItem value={0}>Todos los empleados</MenuItem>
            {employees.map((employee) => {return <MenuItem key={employee.id} value={employee.id}>{employee.name}</MenuItem>})}
          </Select>
          <Select sx={{marginRight: 2, marginBottom: 2}} id="tipoRegistro" value={tipoRegistro} onChange={e => handleTipoRegistro(e)}>
            <MenuItem key={5} value={5}>Todas los tipos de registros</MenuItem>
            <MenuItem key={0} value={0}>Ingreso</MenuItem>
            <MenuItem key={1} value={1}>Despacho</MenuItem>
          </Select>
          {
            issues.length > 0 ? (
              <Box sx={{ display: "inline", marginBottom: 2, marginLeft: 2 }}>
                <Button sx={ {backgroundColor: '#91B38B', padding: 1.5} } variant="contained" onClick={(e) => handleExcel(issues)}>Descargar Registros</Button>
              </Box>
            ) : (<></>)
          }
          {
            filteredIssues.length > 0 ? (
              <Box sx={{ height: '70vh', overflowY: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection={orderBy === 'empleado' ? order : false}>
                    <TableSortLabel active={orderBy === 'empleado'} direction={orderBy === 'empleado' ? order : 'asc'} onClick={(e) => handleRequestSort(e, 'empleado')}>
                      Empleado
                      {orderBy === 'empleado' ? (<Box component="span" sx={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Detalle</TableCell>
                  <TableCell>
                    <TableSortLabel active={orderBy === 'fecha'} direction={orderBy === 'fecha' ? order : 'asc'} onClick={(e) => handleRequestSort(e, 'fecha')}>
                      Fecha
                      {orderBy === 'fecha' ? (<Box component="span" sx={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Tipo registro</TableCell>
                  <TableCell>Cantidad (kilos)</TableCell>
                  {/* <TableCell>Precio de compra</TableCell> */}
                  <TableCell>Imagen</TableCell>
                  <TableCell>Eliminar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {stableSort(filteredIssues, getComparator(order, orderBy)).map((issue) => (renderTable([issue])))}
              </TableBody>
            </Table>
          </Box>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography variant="h3">¡No hay registros!</Typography>
          </div>
            ) 
          }
        </Card>
      )}

      {/* Calculos */}
      {tabValue === 2 &&
        <Card sx={{ px: 2.5, py: 2.5 }} >
          <Typography variant="h4">Calculos</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ValorHora</TableCell>
                  <TableCell>cantidadHoras</TableCell>
                  <TableCell>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calculos.map((calculo, index) => (
                  <TableRow key={index}>
                    <TableCell>{calculo.valorHora}</TableCell>
                    <TableCell>{calculo.cantidadHoras}</TableCell>
                    <TableCell>{calculo.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      }
      <Dialog open={open} onClose={handleClose} fullWidth={false}>
        <DialogContent>
                    {selectedImage && <img src={selectedImage} alt="imagen grande" style={{ width: '90vw', height: '85vh', objectFit: 'contain', margin: '0', display: 'block' }} />}
        </DialogContent>
      </Dialog>
    </div>
  );
}