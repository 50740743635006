import Map from "./mapDrawer";
import Stepper from '@mui/material/Stepper';
import { useEffect, useState } from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Card, Button, Stack, Grid, Input, Select, MenuItem} from "@mui/material";
import Swal from 'sweetalert2';
import jwtDecode from "jwt-decode";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import dotenv from "dotenv";
dotenv.config();
const URL_BACKEND = process.env.REACT_APP_BASE_URL;







export default function RegisterFromWhatsapp() {
    const [activeStep, setActiveStep] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    
    const handleSave = async() => {
        console.log("aprete")
        let decoded = jwtDecode(localStorage.getItem("jwt"));
    
        async function fetchEmpresa () {
            const response = await fetch(`${URL_BACKEND}/empresas/post`, { // publicamos empresa
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'auth': localStorage.getItem("jwt")
                },
                body: JSON.stringify({
                    "nombre": decoded.empresa,
                    "users": JSON.parse(localStorage.getItem("employees")),
                })
            });
            const data = await response.json();
            console.log("empresa", data);
            let empresaId = data.id;
            return empresaId;
        } 
    
        async function fetchUsuarios (empresaId) {
            const response = await fetch(`${URL_BACKEND}/usuarios/post_multiple`, { // publicamos usuarios
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'auth': localStorage.getItem("jwt")
                },
                body: JSON.stringify({
                    "empresa_id": parseInt(empresaId), 
                    "usuarios": JSON.parse(localStorage.getItem("employees")),
                })
            });
            const data = await response.json();
            console.log("peo", data);
            let userId = data.id;
            return userId;
        }
    
        async function fetchCuarteles (userId, empresaId) {
            const response = await fetch(`${URL_BACKEND}/cuarteles/post`, { // publicamos cuarteles
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'auth': localStorage.getItem("jwt")
                },
                body: JSON.stringify({
                    "user_id" : parseInt(userId),
                    "empresa_id": parseInt(empresaId), 
                    "cuarteles": JSON.parse(localStorage.getItem("mapLayers")),
                })
            });
            if (response.status === 200) {
                Swal.fire({
                    title: 'Registro exitoso',
                    text: 'Tus campos han sido registrados exitosamente',
                    icon: 'success',
                    confirmButtonText: 'Entendido'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.setItem("user_id", userId);
                        window.location.href = "dashboard/app";
                    }
                  });
            }
        }
        const empresaId = await fetchEmpresa();
        console.log("empresaId", empresaId);
        const userId = await fetchUsuarios(empresaId);
        console.log("userId", userId);
        await fetchCuarteles(userId, empresaId);

    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === 0){
            Swal.fire({
                title: 'Registro de campos exitoso',
                text: 'Tus campos han sido registrados exitosamente',
                icon: 'success',
                confirmButtonText: 'Entendido'
            });
        }
        if(activeStep === 1){
            Swal.fire({
                title: 'Registro exitoso',
                text: 'Tu personal ha sido registrado exitosamente',
                icon: 'success',
                confirmButtonText: 'Entendido'
            });
        }
    };
    const handlePrev = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const firstEmployeeData = jwtDecode(localStorage.getItem("jwt"));
    const [employees, setEmployees] = useState([{ name: firstEmployeeData.name, email: firstEmployeeData.email, phone: firstEmployeeData.phone, employeeType: "Supervisor"}]);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [employeeType, setEmployeeType] = useState("");
    const [cuarteles, setCuarteles] = useState([]);

    



    const addEmployee = () => {
    setEmployees(prevEmployees => [...prevEmployees, { name,  phone, email, employeeType }]);
    setName("");
    setPhone("");
    setEmail("");
    setEmployeeType("");
    };
    const removeEmployee = () => {
        setEmployees(prevEmployees => prevEmployees.slice(0, -1));
      }

    const welcomePopup = () => {
        Swal.fire({
            title: 'Bienvenido a MIIDO',
            text: 'Simplemente marca tu campo y comienza a digitalizar tus procesos con whatsapp',
            icon: 'info',
            confirmButtonText: 'Entendido'
        });
        setFirstLoad(false);
    };
    useEffect(() => {
        localStorage.setItem("employees", JSON.stringify(employees));
    }
    , [employees]);
    useEffect(() => {
        const data = JSON.parse(localStorage.getItem("mapLayers"));
        setCuarteles(data);
        console.log("data", data);
    }, [activeStep]);

    
    return (
        <>
        {firstLoad && welcomePopup()}
        
        
        <Card sx={{ px: 2.5, py: 2.5 }} style={{ height: "100vh" }} >
        
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={2} md={1}>
                <img src="/assets/logo.svg" alt="SVG as an image" style={{width: '100px', height: '100px'}} />
            </Grid>
            <Grid item xs={5} md={11}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                        <StepLabel onClick={() => setActiveStep(0)}>Seleccionar cuarteles</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel onClick={() => setActiveStep(1)}>Seleccionar personal</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel onClick={() => setActiveStep(2)}>Resumen</StepLabel>
                    </Step>
                </Stepper>
            </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
            {activeStep > 0 ? (
                <Grid item>
                <Button onClick={handlePrev} variant="contained" color="primary">
                    Anterior
                </Button>
                </Grid>
            ): <Grid item></Grid>}
            <Grid item>
                {activeStep < 2 ? (
                <Button onClick={handleNext} variant="contained" color="primary">
                Siguiente
                </Button>
                ) : <Grid item>
                    <Button onClick={handleSave} variant="contained" color="primary">
                        Guardar
                    </Button>

                    </Grid>}

            </Grid>
        </Grid>

            {activeStep === 0 && (
                <Map />
            )}
            {activeStep === 1 && (
            <>
                <h1>Seleccion de personal</h1>
                <TableContainer>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Telefono</TableCell>
                        <TableCell>Tipo de empleado</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {employees.map((employee, index) => (
                        <TableRow key={index}>
                        <TableCell>{employee.name}</TableCell>
                        <TableCell>{employee.email}</TableCell>
                        <TableCell>{employee.phone}</TableCell>
                        <TableCell>{employee.employeeType}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                        <TableRow>
                        <TableCell><Input placeholder="Nombre" value={name} onChange={e => setName(e.target.value)} /></TableCell>
                        <TableCell><Input placeholder="Mail" value={email} onChange={e => setEmail(e.target.value)} /></TableCell>
                        <TableCell><Input placeholder="Telefono" value={phone} onChange={e => setPhone(e.target.value)} /></TableCell>
                        <TableCell><Select
                        value={employeeType}
                        onChange={e => setEmployeeType(e.target.value)}
                        placeholder="Tipo de empleado"
                        >
                            <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                            <MenuItem value={"Operario"}>Operario</MenuItem>
                        </Select>
                        </TableCell>
                    </TableRow>
                </Table>
                </TableContainer>
                
                <IconButton color="primary" onClick={addEmployee}>
                    <AddIcon />
                </IconButton>
                <IconButton color="primary" onClick={removeEmployee}>
                    <RemoveIcon />
                </IconButton>
            </>
            )}
            {activeStep === 2 && (
                <>
                <h1>Resumen</h1>
                <h3>Empleados</h3>
                <TableContainer>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Telefono</TableCell>
                        <TableCell>Tipo de empleado</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {employees.map((employee, index) => (
                        <TableRow key={index}>
                        <TableCell>{employee.name}</TableCell>
                        <TableCell>{employee.email}</TableCell>
                        <TableCell>{employee.phone}</TableCell>
                        <TableCell>{employee.employeeType}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
                <h3>Cuarteles</h3>
                
                <TableContainer>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Superficie [Ha]</TableCell>
                        <TableCell>Cultivo</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {cuarteles.map((cuartel, index) => (
                        <TableRow key={index}>
                        <TableCell>{cuartel.id}</TableCell>
                        <TableCell>{cuartel.superficie}</TableCell>
                        <TableCell>{cuartel.cultivo}</TableCell>
                        </TableRow>
                    ))
                    }
                    </TableBody>
                </Table>
                </TableContainer>

                </>
                

            )}
            
            
            
            
        </Card>
        </>
    );
};