// components/Register.js

import React from 'react';
import { Draggable } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import { GripVertical } from 'lucide-react';

const formatDate = (dateString) => {
  if (!dateString) return ''; // Manejar casos donde dateString es null o undefined
  return dateString.split('T')[0];
};

const Register = ({ register, index, handleDelete }) => {
  const [contextMenu, setContextMenu] = React.useState(null);

  const handleRightClick = (e) => {
    e.preventDefault();

    // Calcula las coordenadas relativas al contenedor del componente
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setContextMenu({
      mouseX: x,
      mouseY: y,
    });
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const convertStringToJson = (string) => {
    try {
      if (string === null) {
        return { titulo_proceso: null };
      }
      if (string.includes("none")) {
        return { titulo_proceso: null };
      }
      return JSON.parse(string);
    } catch (e) {
      return string;
    }
  };

  return (
    <Draggable key={register.id} draggableId={register.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`relative bg-gray-50 p-3 rounded-lg ${snapshot.isDragging ? 'border-2 border-blue-500' : ''}`}
          onContextMenu={handleRightClick}
          onClick={handleClose}
        >
          <div {...provided.dragHandleProps} className="cursor-move">
            <GripVertical className="w-4 h-4 mb-2 text-gray-400" />
          </div>
          <div className="space-y-1 text-base">
            <div><strong>Registro:</strong> {register.detalle}</div>
            <div><strong>Persona:</strong> {register.empleado}</div>
            <div><strong>Fecha:</strong> {formatDate(register.fecha)}</div>
            <div><strong>Ubicacion:</strong> {register.ubicacion !== "null" ? register.ubicacion : "Sin definir"}</div>
            {convertStringToJson(register.categoria).titulo_proceso === null ? (
              <div><strong>No hay recomendación</strong></div>
            ) : (
              <div>
                <strong>Recomendación donde asignar</strong>
                <div><strong>Proceso :</strong> {convertStringToJson(register.categoria).titulo_proceso}</div>
                <div><strong>Tablero :</strong> {convertStringToJson(register.categoria).titulo_tablero}</div>
              </div>
            )}
          </div>
          {contextMenu && (
            <div
              className="absolute z-10 bg-white border rounded shadow-lg"
              style={{
                top: `${contextMenu.mouseY}px`,
                left: `${contextMenu.mouseX}px`,
              }}
            >
              <button
                className="block w-full text-left px-4 py-2 text-base text-red-600 hover:bg-red-100"
                onClick={() => {
                  handleDelete(register);
                  handleClose();
                }}
              >
                Borrar registro
              </button>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default Register;