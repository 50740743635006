import React, { useState, useEffect, useRef} from 'react';
import { Plus, Trash2, Check, Repeat } from 'lucide-react';
import Swal from 'sweetalert2';

const url = process.env.REACT_APP_BASE_URL;

function useClickOutside(ref, onClickOutside) {
  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    // Bind
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

const ReminderView = ({ employees, Empresa_id, jwt, offset }) => {
  const [reminders, setReminders] = useState([]);
  const [newReminder, setNewReminder] = useState({
    employeeNames: [],
    employeeIds: [],
    Empresa_id,
    offset,
    task: '',
    dueTime: '',
    dueDate: '',
    repeat: 'No se repite',
    customRepeatText: '',
    cron: '',
  });
  
  const employeeDropdownRef = useRef(null);
  const [isEmployeeDropdownOpen, setIsEmployeeDropdownOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reminderToDelete, setReminderToDelete] = useState(null);

  useClickOutside(employeeDropdownRef, () => setIsEmployeeDropdownOpen(false));

  const selectAllEmployees = (event) => {
    if (event.target.checked) {
      const allEmployeeNames = employees.map(employee => employee.name);
      const allEmployeeIds = employees.map(employee => employee.id);
      setNewReminder(prev => ({ ...prev, employeeNames: allEmployeeNames, employeeIds: allEmployeeIds }));
    } else {
      setNewReminder(prev => ({ ...prev, employeeNames: [], employeeIds: [] }));
    }
    setIsEmployeeDropdownOpen(false);
  };

  const addReminder = async () => {
    if (newReminder.employeeNames.length > 0 && newReminder.employeeIds.length > 0 && newReminder.task && newReminder.dueDate) {
      await fetch(`${url}/recordatorios/post/reminder`, {
        method: 'POST',
        headers: {
          'auth': jwt,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'
        },
        body: JSON.stringify(newReminder)
      })
        .then(response => response.json())
        .then(data => {
          if (data.message) {
            Swal.fire({
              title: '!Exito!',
              text: data.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            });
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });

      setNewReminder({
        employeeNames: [],
        employeeIds: [],
        Empresa_id,
        offset,
        task: '',
        dueTime: '',
        dueDate: '',
        repeat: 'No se repite',
        customRepeatText: '',
        cron: '',
      });

      await fetch(`${url}/recordatorios/empresa/${Empresa_id}`, {
        method: 'GET',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        }
      })
        .then(response => response.json())
        .then(data => {
          setReminders(data);
        })
        .catch(error => {
          console.error('Error al obtener los datos:', error);
        });
    }
  };

  const confirmDeleteReminder = (reminder) => {
    setReminderToDelete(reminder);
    setIsModalOpen(true);
  };

  const deleteReminder = async () => {
    await fetch(`${url}/recordatorios/delete/${reminderToDelete.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'auth': jwt
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.message === "Recordatorio eliminado") {
          setReminders(reminders.filter(reminder => reminder.id !== reminderToDelete.id));
          Swal.fire(
            '¡Eliminado!',
            'El recordatorio ha sido eliminado.',
            'success'
          );
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    setIsModalOpen(false);
    setReminderToDelete(null);
  };

  function extractCronTime(cronString) {
    let [minute, hour] = cronString.split(' ');

    hour = parseInt(hour);
    minute = parseInt(minute);

    hour = (hour + offset + 24) % 24;

    if (hour > 23) {
      hour -= 24;
    } else if (hour < 0) {
      hour += 24;
    }

    const formattedHour = String(hour).padStart(2, '0');
    const formattedMinute = String(minute).padStart(2, '0');

    return `${formattedHour}:${formattedMinute}`;
  }

  const fixDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const toggleEmployee = (employee) => {
    setNewReminder(prev => ({
      ...prev,
      employeeNames: prev.employeeNames.includes(employee.name)
        ? prev.employeeNames.filter(e => e !== employee.name)
        : [...prev.employeeNames, employee.name],
      employeeIds: prev.employeeIds.includes(employee.id)
        ? prev.employeeIds.filter(e => e !== employee.id)
        : [...prev.employeeIds, employee.id]
    }));
  };

  // Función para calcular el mensaje personalizado
  const handleRepeatChange = (event) => {
    const date = event.target.dueDate ? new Date(event.target.dueDate) : new Date(newReminder.dueDate);
    const daysOfWeek = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
    const repeatValue = event.target.value;
    let customRepeatText = '';

    if (repeatValue === 'weekly' && newReminder.dueDate) {
      const dayOfWeek = daysOfWeek[date.getUTCDay()];
      customRepeatText = `Cada semana el ${dayOfWeek}`;
    } else if (repeatValue === 'monthly' && newReminder.dueDate) {
      const dayOfWeek = daysOfWeek[date.getUTCDay()];
      const weekOfMonth = Math.ceil(date.getUTCDate() / 7);
      const totalDaysInMonth = new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0).getUTCDate();
      const totalWeeksInMonth = Math.ceil(totalDaysInMonth / 7);
      if (weekOfMonth > totalWeeksInMonth) {
        console.warn("El mes seleccionado no tiene tantas semanas.");
        customRepeatText = `No es posible programar el evento para la ${weekOfMonth}º ${dayOfWeek}, ya que el mes no tiene tantas semanas.`;
      } else {
        customRepeatText = `Cada mes el ${weekOfMonth}º ${dayOfWeek}`;
      }
    } else if (repeatValue === 'annually' && newReminder.dueDate) {
      const day = date.getUTCDate();
      const month = date.toLocaleDateString('es-ES', { month: 'long', timeZone: 'UTC' });
      customRepeatText = `Anualmente el ${day} de ${month}`;
    } else if (repeatValue === 'daily') {
      customRepeatText = 'Cada día';
    } else {
      customRepeatText = 'No se repite';
    }

    let cron = "";
    let daymonth = date.getUTCDate();   /* 1-31 */
    let month = date.getUTCMonth() + 1; /* 1-12 */
    let dayweek = date.getUTCDay();     /* 0-6 */
    const weekOfMonth = Math.ceil(daymonth / 7);
    const totalDaysInMonth = new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0).getUTCDate();

    let [hour, minute] = newReminder.dueTime.split(':');
    let plusDay = false;

    if (hour - offset > 24) {
      hour = (hour - offset) % 24;
      plusDay = true;
      if (daymonth + 1 > totalDaysInMonth) {
        daymonth = 1;
        if (dayweek + 1 === 7) {
          dayweek = 0;
        } else {
          dayweek += 1;
        }
        if (month + 1 > 12) {
          month = 1;
        } else {
          month += 1;
        }
      } else {
        daymonth += 1;
        if (dayweek + 1 === 7) {
          dayweek = 0;
        } else {
          dayweek += 1;
        }
      }
    } else {
      hour = hour - offset;
    }


    if (repeatValue === 'daily') {
      if (plusDay) {
        cron = `${minute} ${hour} * * 2-6`;
      } else {
        cron = `${minute} ${hour} * * 1-5`;
      }
    } else if (repeatValue === 'weekly') {
      cron = `${minute} ${hour} * * ${dayweek}`;
    } else if (repeatValue === 'monthly') {
      if (weekOfMonth === 1) {
        if (plusDay) {
          cron = `${minute} ${hour} 2-8 * ${dayweek}`;
        } else {
          cron = `${minute} ${hour} 1-7 * ${dayweek}`;
        }
      } else if (weekOfMonth === 2) {
        if (plusDay) {
          cron = `${minute} ${hour} 9-15 * ${dayweek}`;
        } else {
          cron = `${minute} ${hour} 8-14 * ${dayweek}`;
        }
      } else if (weekOfMonth === 3) {
        if (plusDay) {
          cron = `${minute} ${hour} 16-22 * ${dayweek}`;
        } else {
          cron = `${minute} ${hour} 15-21 * ${dayweek}`;
        }
      } else if (weekOfMonth === 4) {
        if (plusDay) {
          cron = `${minute} ${hour} 23-29 * ${dayweek}`;
        } else {
          cron = `${minute} ${hour} 22-28 * ${dayweek}`;
        }
      } else if (weekOfMonth === 5) {
        if (plusDay) {
          cron = `${minute} ${hour} 30,31,1 * ${dayweek}`;
        } else {
          cron = `${minute} ${hour} 29-31 * ${dayweek}`;
        }
      }
    } else if (repeatValue === 'annually') {
      cron = `${minute} ${hour} ${daymonth} ${month} *`;
    } else {
      cron = `${minute} ${hour} ${daymonth} ${month} *`
    }

    console.log(cron, offset);

    setNewReminder(prev => ({
      ...prev,
      repeat: repeatValue,
      customRepeatText,
      cron
    }));
  };

  useEffect(() => {
    fetch(`${url}/recordatorios/empresa/${Empresa_id}`, {
      method: 'GET',
      headers: {
        'auth': jwt,
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        setReminders(data);
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });
  }, [Empresa_id, jwt]);

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mb-6 bg-white p-4 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">Agregar nuevo recordatorio</h2>
        <div className="flex flex-wrap -mx-2">
          <div className="relative flex-grow m-2">
            <button
              onClick={() => setIsEmployeeDropdownOpen(!isEmployeeDropdownOpen)}
              className="w-full p-2 border rounded text-left bg-white"
            >
              {newReminder.employeeNames.length > 0
                ? `${newReminder.employeeNames.length} empleado(s) seleccionados`
                : 'Seleccionar empleados'}
            </button>
            {isEmployeeDropdownOpen && (
              <div ref={employeeDropdownRef} className="absolute z-10 w-full mt-1 bg-white border rounded shadow-lg">
                <div className="mt-4 inline-flex items-center text-sm text-black hover:bg-gray-100 p-2 rounded">
                  <input
                    type="checkbox"
                    onChange={selectAllEmployees}
                    checked={newReminder.employeeNames.length === employees.length}
                    className="mr-2"
                  />
                  <label>Seleccionar todos los empleados</label>
                </div>
                {employees.map(employee => (
                  <label key={employee.id} className="flex items-center p-2 hover:bg-gray-100">
                    <input
                      type="checkbox"
                      checked={newReminder.employeeNames.includes(employee.name)}
                      onChange={() => toggleEmployee(employee)}
                      className="mr-2"
                    />
                    {employee.name}
                    {newReminder.employeeNames.includes(employee.name) && (
                      <Check size={16} className="ml-auto text-green-500" />
                    )}
                  </label>
                ))}
              </div>
            )}
          </div>
          <input
            type="text"
            placeholder="Recordatorio"
            className="flex-grow m-2 p-2 border rounded"
            value={newReminder.task}
            onChange={(e) => setNewReminder({ ...newReminder, task: e.target.value })}
          />
          <input
            type="time"
            className="flex-grow m-2 p-2 border rounded"
            value={newReminder.dueTime}
            onChange={(e) => {
              const newTime = e.target.value;
              setNewReminder(prev => ({
                ...prev,
                dueTime: newTime,
              }));
            }}
          />
          <input
            type="date"
            className="flex-grow m-2 p-2 border rounded"
            value={newReminder.dueDate}
            onChange={(e) => {
              const newDate = e.target.value;
              setNewReminder(prev => ({
                ...prev,
                dueDate: newDate,
              }));
              handleRepeatChange({ target: { value: newReminder.repeat, dueDate: newDate } });
            }}
          />
          <button
            onClick={addReminder}
            className="flex-grow m-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
          >
            <Plus className="inline-block mr-2" size={18} /> Agregar recordatorio
          </button>
          <button
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
            className="p-2"
          >
            <Repeat size={24} className="text-gray-700 hover:text-gray-900 transition duration-300" />
          </button>
        </div>
        {isSettingsOpen && (
          <div className="mt-4 p-4 border rounded bg-gray-50">
            <h3 className="text-sm font-medium text-gray-700 mb-2">Repetir recordatorio:</h3>
            <select
              value={newReminder.repeat}
              onChange={handleRepeatChange}
              className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="No se repite">No se repite</option>
              <option value="daily">Cada día</option>
              <option value="weekly">Cada semana</option>
              {/* <option value="monthly">Cada mes</option> */}
              <option value="annually">Anualmente</option>
            </select>
            {newReminder.customRepeatText && (
              <p className="mt-2 text-gray-700 text-sm italic">{newReminder.customRepeatText}</p>
            )}
          </div>
        )}
      </div>

      {/* Lista de recordatorios */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Empleados</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tarea</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hora</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha de vencimiento</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Repetir</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {reminders.map((reminder) => (
              <tr key={reminder.id}>
                <td className="px-6 py-4">{/* formatEmployeeNames(reminder.employeeNames.join(", ")) */}{reminder.empleado}</td>
                <td className="px-6 py-4 whitespace-nowrap">{reminder.titulo}</td>
                <td className="px-6 py-4 whitespace-nowrap">{extractCronTime(reminder.cron)}</td>
                <td className="px-6 py-4 whitespace-nowrap">{fixDate(reminder.fecha)}</td>
                <td className="px-6 py-4 whitespace-nowrap">{reminder.customRepeatText}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => confirmDeleteReminder(reminder)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal de eliminación */}
      {isModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <Trash2 className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Eliminar recordatorio
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        ¿Estás seguro de que deseas eliminar este recordatorio? Esta acción no se puede deshacer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={deleteReminder}
                >
                  Eliminar
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReminderView;
